import elementPath from 'common/element_path';

$.extend(bootstrap.Modal.Default, {
	backdrop: 'static',
	keyboard: false,
	focus: false
});

$(document)
	.on('show.bs.modal', '.modal', function (event) {
		$app.addClass('modal-open');
		let $modal = $(this);
		let modalZIndex = _.size($('.modal.show')) + 1050 + 1;
		let backdropZIndex = modalZIndex - 1;

		$modal.css({ 'z-index': modalZIndex });
		if ($modal.data('bs-backdrop') !== false) {
			$modal.appendTo($app);
		}
		setBackdropsOrder(backdropZIndex);
		$('.modal.show:not(.minimized):last').addClass('blur');
	})
	.on('shown.bs.modal', '.modal.show', function (event) {
		let $modal = $(this);

		let $elementToFocus = $modal.find('gt-input:not([plainText]):visible:first');
		if (_.isEmpty($elementToFocus)) {
			$elementToFocus = $modal.find('gt-textarea:visible:first');
		}
		if (_.isEmpty($elementToFocus)) {
			$elementToFocus = $modal.find('.modal-footer gt-button:first');
		}
		$elementToFocus.focus();
		
		if ($modal.hasClass('bootbox') && _.isEmpty($elementToFocus)) {
			$modal.find('.modal-footer .btn.focus').focus();
			return;
		}

		if ($modal.is('form') && !$modal.hasClass('skip-bond-log')) {
			app.bond.logEvent(app.bond.SHOW_FORM, {
				id: $modal.attr('id'),
				triggeredBy: elementPath(event.relatedTarget)
			});
		}
	})
	.on('hide.bs.modal', '.modal', function (event) {
		let $modal = $(this);
		let shouldHide = true;
		
		if ($modal.data('should-hide')) {
			$modal.removeData('should-hide');

			$('.modal.show:last').removeClass('blur');
			if ($modal.is('form')) {
				$modal.reset();
			}
		} else {
			event.preventDefault();

			setTimeout(() => {
				if (shouldHide) {
					$modal.data('should-hide', shouldHide);
					$modal.modal('hide');
				}
			}, 0);
		}
		
		// If a child modal is opened while this one is closed, then prevent it from being closed (hacking BS5 logic)
		$(document)
			.one('show.bs.modal', '.modal', () => {
				shouldHide = false;
				// After this, the setTimeout callback will be called
			});
	})
	.on('hidden.bs.modal', '.modal', function (event) {
		let $modal = $(this);
		let backdropZIndex = _.size($('.modal.show')) + 1050 - 1;
		$app.addClass('modal-open');
		setBackdropsOrder(backdropZIndex);
		$('.modal.show:last').removeClass('blur');
		if (_.isEmpty($('.modal.show:not(.minimized)'))) {
			$app.removeClass('modal-open');
		}

		$modal.find('.oopsy').addClass('d-none');
	});

const setBackdropsOrder = (zIndex) => {
	$('.modal-backdrop').addClass('d-none');
	$('.modal-backdrop.show:last').css('z-index', zIndex).removeClass('d-none');
};
