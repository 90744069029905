import Store, { INIT_STATE } from './store';
import Task from './task';

const GET_EMAIL_PROVIDERS = 'GET_EMAIL_PROVIDERS';
const GET_CONTACT_POINTS = 'GET_CONTACT_POINTS';
const ADD_EMAIL_PROVIDER = 'ADD_EMAIL_PROVIDER';
const UPDATE_EMAIL_PROVIDER = 'UPDATE_EMAIL_PROVIDER';
const CREATE_CONTACT_POINT = 'CREATE_CONTACT_POINT';
const UPDATE_CONTACT_POINT = 'UPDATE_CONTACT_POINT';
const DELETE_CONTACT_POINT = 'DELETE_CONTACT_POINT';

let loadingQueueEmailProviders = null;
let loadingQueueContactPoints = null;

class Monitoring extends Store {
	constructor() {
		const initialState = {
			emailProviders: null,
			contactPoints: null
		};
		
		super();
		this.setState(initialState, INIT_STATE);
	}
	
	fetchEmailProviders() {
		if (!_.isNull(loadingQueueEmailProviders)) {
			return loadingQueueEmailProviders;
		}
		let emailProviders;
		loadingQueueEmailProviders = this.get('/api/monitoring/email-providers')
			.then((response) => {
				emailProviders = response.data;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				emailProviders = false;
			})
			.then(() => {
				loadingQueueEmailProviders = null;
				this.setState({ emailProviders: emailProviders }, GET_EMAIL_PROVIDERS);
				return emailProviders;
			});
		return loadingQueueEmailProviders;
	}
	
	getEmailProviders() {
		let emailProviders = this.getStateProperty('emailProviders');
		if (!_.isNull(emailProviders)) {
			return this.createPromise(emailProviders);
		}
		
		return this.fetchEmailProviders();
	}
	
	fetchContactPoints() {
		if (!_.isNull(loadingQueueContactPoints)) {
			return loadingQueueContactPoints;
		}
		let contactPoints;
		loadingQueueContactPoints = this.get('/api/monitoring/contact-points')
			.then((response) => {
				contactPoints = response.data;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				contactPoints = false;
			})
			.then(() => {
				loadingQueueContactPoints = null;
				this.setState({ contactPoints: contactPoints }, GET_CONTACT_POINTS);
				return contactPoints;
			});
		return loadingQueueContactPoints;
	}
	
	getContactPoints() {
		let contactPoints = this.getStateProperty('contactPoints');
		if (!_.isNull(contactPoints)) {
			return this.createPromise(contactPoints);
		}
		
		return this.fetchContactPoints();
	}
	
	sendTestEmail(configuration) {
		return this.post(
			'/api/monitoring/email-providers-validator',
			configuration
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				return false;
			});
	}
	
	addSmtpServer(configuration) {
		return this.post(
			'/api/monitoring/email-providers',
			configuration
		)
			.then((response) => {
				Task.wait(response.data.jobId, (task) => {
					if (task.status.toLowerCase() === 'succeeded') {
						let emailProviders = this.getStateProperty('emailProviders');
						emailProviders.push(task.result);
						this.setState({ emailProviders: emailProviders }, ADD_EMAIL_PROVIDER);
					}
				});
				return response.data;
			});
	}
	
	updateSmtpServer(configuration) {
		let uid = configuration.uid;
		delete configuration.uid;
		return this.patch(
			`/api/monitoring/email-providers/${uid}`,
			configuration
		)
			.then((response) => {
				Task.wait(response.data.jobId, (task) => {
					if (task.status.toLowerCase() === 'succeeded') {
						let emailProviders = _.reject(this.getStateProperty('emailProviders'), { uid: uid });
						emailProviders.push(task.result);
						this.setState({ emailProviders: emailProviders }, UPDATE_EMAIL_PROVIDER);
					}
				});
				return response.data;
			});
	}
	
	createContactPoint(contactPoint) {
		return this.post(
			'/api/monitoring/contact-points',
			contactPoint
		)
			.then((response) => {
				Task.wait(response.data.jobId, (task) => {
					if (task.status.toLowerCase() === 'succeeded') {
						let contactPoints = this.getStateProperty('contactPoints');
						contactPoints.push(task.result);
						this.setState({ contactPoints: contactPoints }, CREATE_CONTACT_POINT);
					}
				});
				return response.data;
			});
	}
	
	updateContactPoint(contactPoint) {
		let uid = contactPoint.uid;
		delete contactPoint.uid;
		return this.patch(
			`/api/monitoring/contact-points/${uid}`,
			contactPoint
		)
			.then((response) => {
				Task.wait(response.data.jobId, (task) => {
					if (task.status.toLowerCase() === 'succeeded') {
						let contactPoints = _.reject(this.getStateProperty('contactPoints'), { uid: uid });
						contactPoints.push(task.result);
						this.setState({ contactPoints: contactPoints }, UPDATE_CONTACT_POINT);
					}
				});
				return response.data;
			});
	}
	
	deleteContactPoint(uid) {
		return this.delete(
			`/api/monitoring/contact-points/${uid}`,
		)
			.then((response) => {
				Task.wait(response.data.jobId, (task) => {
					if (task.status.toLowerCase() === 'succeeded') {
						let contactPoints = _.reject(this.getStateProperty('contactPoints'), { uid: uid });
						this.setState({ contactPoints: contactPoints }, DELETE_CONTACT_POINT);
					}
				});
				return response.data;
			});
	}
}

export default new Monitoring();
