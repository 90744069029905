import 'datatables.net';
import 'datatables.net-scroller';
import 'datatables.net-fixedcolumns';
import 'datatables.net-rowgroup';
import 'datatables.net-select';
import 'datatables.net-plugins/features/conditionalPaging/dataTables.conditionalPaging';
import 'datatables_extra';

export default function table() {
	$(document.body)
		.on('click', '.list-details .list .table > tbody > tr[data-row]', (event) => {
			let $selectedRow = $(event.currentTarget);
			let $module = $selectedRow.closest('.list-details');
			let controller = $module.attr('id');
			let row = $selectedRow.data('row');
			if (!row) {
				return;
			}
		
			$.table.add(controller, row);
		});
	
	$.fn.dataTable.ext.errMode = 'throw';
	
	$.extend($.fn.dataTable.defaults, {
		dom: '<"actions bd-blue-300 container-fluid shadow-sm d-none"<"row align-items-center"<"col-auto fw-bolder"i><"col">>><"filter container-fluid ps-0"<"row align-items-center"<"col-auto"f><"col d-flex"><"col-auto"i>>>t',
		deferRender: true,
		stateSave: true,
		stateDuration: 0,
		autoWidth: false,
		info: true,
		scroller: true,
		paginate: false,
		paginationType: 'simple',
		lengthChange: false,
		conditionalPaging: true,
		language: {
			search: '',
			searchPlaceholder: ' ',
			zeroRecords: app.t('app:phrases.zeroRecords'),
			info: app.t('app:phrases.info'),
			infoEmpty: app.t('app:phrases.infoEmpty'),
			infoFiltered: app.t('app:phrases.infoFiltered'),
			select: {
				rows: {
					_: app.t('app:phrases.countSelected_other'),
					0: '',
					1: app.t('app:phrases.countSelected_one')
				}
			},
			paginate: {
				previous: '<i class="far fa-chevron-left"></i>',
				next: '<i class="far fa-chevron-right"></i>'
			},
			lengthMenu: `<gt-select class="page-length" label="${app.t('app:labels.show')}"></gt-select>`
		},
		scrollY: 'calc(100vh - 235px)',
		preDrawCallback: (settings) => {
			let $table = $(settings.nTableWrapper);
			let $search = $table.find('.filter input[type="search"]');
			if (!$search.hasClass('form-control')) {
				$search.addClass('form-control').detach();
				$table.find('.dt-search').prepend($search);
				$table.find('.dt-search').addClass('form-floating');
				$table.find('.dt-search label').append(app.t('labels.search'));
			}
		},
		infoCallback: function (settings, start, end, max, total, pre) {
			if (this.api().rows( { selected: true } ).count() > 0) {
				return '';
			}

			return pre;
		},
		initComplete: function () {
			let api = this.api();
			let $table = $(api.table().container());
			api
				.on('select deselect', (event) => {
					let selectedRowsCount = api.rows( { selected: true } ).count();
					$table.find('.actions').toggleClass('d-none', selectedRowsCount === 0);
					$table.find('.filter').toggleClass('d-none', selectedRowsCount !== 0);
					api.draw(false);
				});
			$table
				.on('change', 'th.select .select', (event) => {
					let $checkbox = $(event.currentTarget);
					let isIndeterminate = $checkbox.prop('indeterminate');
					let isChecked = $checkbox.prop('checked');
					$checkbox.prop({
						'indeterminate': false,
						'checked': isIndeterminate || isChecked
					});
					if ($checkbox.prop('checked')) {
						api.rows({ filter: 'applied', search: 'applied' }).select();
					} else {
						api.rows().deselect();
					}
				})
				.on('change', '.page-length', (event) => {
					api.page.len($(event.currentTarget).val()).draw();
				});
		},
		stateSaveCallback: function (settings, data) {
			try {
				let id = $(_.first(settings.oInstance)).attr('id');
				localStorage.setItem(`DataTables_${id}`, JSON.stringify(data));
			} catch (error) {
				console.log(error);
			}
			
			let api = this.api();
			let $table = $(settings.nTableWrapper);
			if (!_.isEmpty($table.find('.dt-scroll-head thead th.select'))) {
				let rowsCount = api.rows({ filter: 'applied', search: 'applied' }).count();
				let selectedRowsCount = api.rows( { selected: true } ).count();
				let $rowsSelect = $table.find('.dt-scroll-head thead th.select .select');
				if (selectedRowsCount === 0) {
					$rowsSelect.prop({
						'indeterminate': false,
						'checked': false
					});
				} else if (rowsCount === selectedRowsCount) {
					$rowsSelect.prop({
						'indeterminate': false,
						'checked': true
					});
				} else {
					$rowsSelect.prop('indeterminate', true);
				}
			}
			if (!_.isEmpty($table.find('.page-length'))) {
				_.first($table.find('.page-length')).options = `<option value="10" data-content="10" ${settings._iDisplayLength === 10 ? 'selected' : ''}></option>
					<option value="25" data-content="25" ${settings._iDisplayLength === 25 ? 'selected' : ''}></option>
					<option value="50" data-content="50" ${settings._iDisplayLength === 50 ? 'selected' : ''}></option>
					<option value="-1" data-content="${app.t('labels.all')}" ${settings._iDisplayLength === -1 ? 'selected' : ''}></option>`;
			}
		},
		stateLoadCallback: (settings, callback) => {
			try {
				let id = $(_.first(settings.oInstance)).attr('id');
				callback(JSON.parse(localStorage.getItem(`DataTables_${id}`)));
			} catch (error) {
				console.log(error);
			}
		}
	});

	$.extend({
		table: {
			rows: JSON.parse($.hash.get('rows') || '{}'),
			add: function (controller, row) {
				if (!this.rows[controller]) {
					this.rows[controller] = {};
				}

				this.rows[controller] = row;
				$.hash.add({ rows: JSON.stringify(this.rows) });
				return this;
			},
			clear: function (controller) {
				if (!this.rows[controller]) {
					return this;
				}

				this.rows[controller] = {};
				$.hash.add({ rows: JSON.stringify(this.rows) });
				return this;
			},
			expand: function (controller) {
				let $page = $(`#${_.kebabCase(controller)}`);
				let $table = $page.find('.dt-scroll-body .dataTable');
				let table = $table.DataTable();
				if (!table) {
					return this;
				}
			
				let row = this.rows[controller] || null;
				if (!row) {
					let tableRows = table.rows(':not(.dtrg-group)', { filter: 'applied', search: 'applied' }).nodes();
					row = $(_.first(tableRows)).data('row');
					if (row) {
						this.rows[controller] = row;
						$.hash.add({ rows: JSON.stringify(this.rows) });
					}
				}
				
				let rows = table.rows(`[data-row="${row}"]`, { filter: 'applied', search: 'applied' });
				if (rows.count() > 0) {
					rows.every(function () {
						let $row = $(this.node());
						if (_.isEmpty($page.find('.details .tab-content'))) {
							$page.find('.details').html(app.render('emptySelection', { hasSpinner: true }));
						}
						$row.trigger('click');
					});
				} else {
					let $row = $table.find(`.dtrg-group[data-row="${row}"]`);
					if (!_.isEmpty($row)) {
						$row.trigger('click');
					} else {
						$page.find('.details').html(app.render('emptySelection'));
					}
				}
			
				return this;
			},
			search: function (controller, string) {
				let $page = $(`#${_.kebabCase(controller)}`);
				let $table = $page.find('.dt-scroll-body .dataTable');
				let table = $table.DataTable();
				if (!table) {
					return this;
				}
				
				table.search(string).draw();
				
				return this;
			}
		}
	});
}
