import Clock from 'clock';
import md5 from 'crypto-js/md5';
import 'jquery.hash';
import 'jquery.button';
import 'lodash_mixin';
import 'augment';
import 'http_client';
import 'gnmenu';
import 'button';
import 'tooltip';
import 'modal';
import 'dropdown';
import 'form';
import 'card';
import 'moment-duration-format';
import 'common/game';
import 'components';

import * as i18n from 'i18n/i18n';
import * as modules from 'modules';
import * as continuousAuth from 'common/continuous_auth';
import * as restart from 'system/restart';
import * as shutdown from 'system/shutdown';
import dialogModule from 'dialog';
import navigationModule from 'navigation';
import tableModule from 'table';

import * as authService from 'login/service';
import * as systemService from 'system/service';
import * as firmwareService from 'firmware/service';
import * as timeService from 'time/service';
import * as centralService from 'central/service';

import * as virtualApplianceLicenceExpired from 'central/virtual_appliance_licence_expired';
import * as alertsConfigure from 'alert/configure';

import errorInitPartial from 'views/common/error_init.html';
import fetchingPartial from 'views/common/fetching.html';
import oopsyPartial from 'views/common/oopsy.html';
import emptySelectionPartial from 'views/common/empty_selection.html';
import emptyStatePartial from 'views/common/empty_state.html';
import confirmIdentityPartial from 'views/common/identify_verification.html';
import languageDropdownPartial from 'views/common/language_dropdown.html';
import gamePartial from 'views/common/game.html';
import header from 'views/header.html';
import main from 'views/main.html';

const templates = new Map();

moment.updateLocale(i18n.getLanguage() || 'en', {
	week: {
		dow: 1 // Monday is the first day of the week
	}
});
window.moment = moment;
window.$app = $('#app');
window.product = {};
window.app = {
	menu: null,
	clock: null,
	notifier: null,
	history: history,
	bond: bond,
	isAuthenticated: false,
	user: {},
	controller: null,
	view: null,
	services: {
		auth: {
			isOnline: true
		}
	},
	continuousAuth: continuousAuth,
	language: i18n.getLanguage(),
	changeLanguage: (language) => {
		i18n.changeLanguage(language);
		moment.locale(language);
	},
	t: (path, options) => {
		return i18n.t(path, options);
	},
	render: (string, options) => {
		const partials = {
			fetching: fetchingPartial,
			oopsy: oopsyPartial,
			emptySelection: emptySelectionPartial,
			emptyState: emptyStatePartial,
			confirmIdentity: confirmIdentityPartial
		};
		try {
			let hash = md5(partials[string] ?? string).toString();
			let template;
			if (templates.has(hash)) {
				template = templates.get(hash);
			} else {
				template = _.template(partials[string] ?? string);
				templates.set(hash, template);
			}
			
			return template({ ...options, ...partials });
		} catch (error) {
			console.error(error);
			return `<div class="flex-fill align-self-center text-center text-danger">${app.t('phrases.viewCouldNotBeRendered')}</div>`;
		}
	},
	initializeUser: () => {
		let user = localStorage.getItem('user');
		if (_.isEmpty(user)) {
			app.user = {};
			return;
		}
		
		app.user = JSON.parse(user);
	}
};

app.history.previousPathname = app.history.location.pathname;
app.history.listen(({ action, location }) => {
	let event = (location.state ? location.state.event : null);
	let previousPathname = app.history.previousPathname;
	app.history.previousPathname = app.history.location.pathname;
	if (previousPathname === app.history.location.pathname && _.isEmpty(event)) {
		return;
	}
	
	setAppRoute();
	
	if (app.menu) {
		app.menu.selectMenu(app.history.location.pathname);
	}
	
	if (action.toLowerCase() === 'pop') {
		renderModule();
	}
	
	if (action.toLowerCase() === 'push') {
		if (event === 'render-layout') {
			modules.load()
				.then(() => {
					renderLayout();
				});
			return;
		}
		
		renderModule();
	}
});

$app
	.on('enable.hyper.primaryActions', (event, controller, view) => {
		view = view || 'index';
		let module = modules.getList()[controller][view];
		module.object.getContainer().find('.module-header .primary-actions').addClass('loaded');
	})
	.on('disable.hyper.primaryActions', (event, controller, view) => {
		view = view || 'index';
		let module = modules.getList()[controller][view];
		module.object.getContainer().find('.module-header .primary-actions').removeClass('loaded');
	})
	.on('click', '.something-went-wrong .try-again', (event) => {
		event.preventDefault();
		$app.find('main > .container-fluid > div').html(`
			<span class="spinner-border mb-4"></span>
			<div class="message">${app.t('labels.loading')}</div>
		`);
		setTimeout(init, 1000);
	});

const init = () => {
	dialogModule();
	tableModule();
	authService.subscribe({
		handleSubscription: (state) => {
			if (state.health === false) {
				renderInitError();
				return;
			}
			
			app.services = state.health.services;
			
			if (_.includes(['notconfigured', 'initializing'], (state?.health?.state ?? '').toLowerCase())) {
				renderServicesLoading();
				return;
			}
			
			authService.getStatus()
				.then((auth) => {
					app.isAuthenticated = auth.isAuthenticated;
					startup();
				});
		}
	});
};

const startup = () => {
	app.initializeUser();
	
	modules.load()
		.then(() => {
			product.firmware = {};
			product.dateTime = {
				date: null,
				timezone: null
			};
			
			let authService = _.find(app.services, { name: 'auth' });
			if (authService.state.toLowerCase() !== 'running') {
				throw new Error('Something is wrong with the authorization service.');
			}
			
			if (!app.isAuthenticated) {
				continueLoading();
				return;
			}
			
			return Promise.allSettled([
				systemService.getProduct(),
				timeService.fetchTime(),
				centralService.getDetails()
			])
				.then(([productDetails, timeDetails, centralDetails]) => {
					if (productDetails.status === 'rejected' || timeDetails.status === 'rejected' || centralDetails.status === 'rejected') {
						throw new Error();
					}
					
					_.assign(product, productDetails.value);
					_.assign(product.dateTime, timeDetails.value);
					
					let central = centralDetails.value;
					if (product.isInAssemblyMode) {
						product.isActivated = true;
						central.maintenance = {
							isExpired: false
						};
					} else {
						product.isActivated = central.isActivated;
					}
					
					centralService.setMaintenance(central.maintenance);
				})
				.then(() => {
					Promise.allSettled([
						firmwareService.getFirmware(),
						firmwareService.getUpdates(),
						firmwareService.getUpdate()
					])
						.then(([firmware, updates, update]) => {
							product.firmware.version = (firmware.status === 'fulfilled' ? firmware.value.localVersion : false);
							product.firmware.updates = (updates.status === 'fulfilled' ? updates.value : []);
							product.firmware.update = (update.status === 'fulfilled' ? update.value : {});
							continueLoading();
						});
				});
		})
		.catch((error) => {
			console.error(error);
			renderInitError();
		});
};

const renderInitError = () => {
	$app
		.removeClass('bootstrap')
		.addClass('security')
		.html(app.render(main, { languageDropdownPartial: languageDropdownPartial }));
	$app.find('main > .container-fluid > div').html(app.render(errorInitPartial));
};

const renderServicesLoading = () => {
	let servicesCount = _.size(app.services);
	let runningServicesCount = _.size(_.filter(app.services, (service) => { return service.state.toLowerCase() === 'running'; }));
	let runningPercentage = (100 * runningServicesCount) / servicesCount;
	$app.find('.spinner-border').addClass('d-none');
	_.first($app.find('gt-progress').removeClass('d-none')).setValue(runningPercentage);
	$app.find('.message').removeClass('d-none').html(app.t('system:labels.stillLoading'));
};

const continueLoading = () => {
	// here you can do things like filter out modules before rendering
	render();
};

const render = () => {
	if (!product.isActivated || !app.isAuthenticated) {
		let urlParams = new URLSearchParams(app.history.location.search);
		let pin = urlParams.get('pin');

		if (app.controller !== 'login') {
			app.history.push({
				pathname: '/login',
				search: pin ? `?pin=${pin}` : '',
				hash: location.hash
			},
			{ event: 'render-layout' });
		}

		return;
	}
	
	let [controller, view] = _.drop(_.split(app.history.location.pathname, '/'));
	if (!_.isEmpty(product.firmware.update.job) && !product.firmware.update.job.isAcknowledged && controller !== 'system' && view !== 'restart') {
		app.history.push({ pathname: '/firmware/update', hash: location.hash }, { event: 'render-layout' });
		return;
	}
	
	if (app.controller === 'login') {
		app.history.push({ pathname: '/dashboard', hash: location.hash }, { event: 'render-layout' });
		return;
	}
	
	renderLayout();
};

const renderLayout = () => {
	setAppRoute();
	
	let navigation = navigationModule();
	let [controller, view] = _.drop(_.split(app.history.location.pathname, '/'));
	$app.removeClass('bootstrap');
	$app.toggleClass('security', (!app.isAuthenticated || !product.isActivated || (controller === 'system' && (view === 'restart' || view === 'shutdown'))));
	$app.toggleClass('firmware-update', app.isAuthenticated && (!_.isEmpty(product.firmware.update.job) && !product.firmware.update.job.isAcknowledged && controller === 'firmware' && view === 'update'));
	
	$app.html(
		app.render(header, { renderNavigation: navigation.render, languageDropdownPartial: languageDropdownPartial }) +
		app.render(main, { languageDropdownPartial: languageDropdownPartial }) +
		gamePartial
	);
	
	app.clock = new Clock();
	app.notifier = document.querySelector('gt-notifier');
	app.notifier.setClock(app.clock);
	
	if (app.controller !== 'login' && app.view !== 'console' && app.view !== 'restart' && app.view !== 'shutdown') {
		app.clock.setReferenceDateTime();
		app.menu = new gnMenu($('#gn-menu'));
		modules.getList().logout.index.object.init();
		modules.getList().tasks.index.object.initOngoingTasksCounter();
		modules.getList()?.firmware?.index?.object?.updateMenuBadge(product.firmware.updates);
		modules.getList()?.firmware?.index?.object?.showNotifications();
		modules.getList()?.vmwareServers?.index?.object?.init();
		
		virtualApplianceLicenceExpired.init();
		alertsConfigure.init();
		restart.init();
		shutdown.init();
	}
	
	renderModule();
};

const hasModule = () => {
	return modules.getList()[app.controller] && modules.getList()[app.controller][app.view];
};

const setAppRoute = () => {
	let [controller, view] = _.drop(_.split(app.history.location.pathname, '/'));

	if (app.isAuthenticated && product.isActivated) {
		let isOnFirmwareUpdatePage = (controller === 'firmware' && view === 'update');
		let isOnSystemRestartPage = (controller === 'system' && view === 'restart');
		if (!_.isEmpty(product.firmware.update.job) && !product.firmware.update.job.isAcknowledged && !isOnFirmwareUpdatePage && !isOnSystemRestartPage) {
			app.history.push({ pathname: '/firmware/update', hash: location.hash });
			return;
		}

		if (
			controller === 'login' ||
			(_.isEmpty(product.firmware.update.job) && isOnFirmwareUpdatePage) ||
			(!_.isEmpty(product.firmware.update.job) && product.firmware.update.job.isAcknowledged && isOnFirmwareUpdatePage)
		) {
			app.history.push({ pathname: '/dashboard', hash: location.hash });
			return;
		}
	}
	
	app.controller = (controller || 'dashboard');
	app.view = (view || 'index');
	
	if (!hasModule()) {
		app.controller = 'errors';
		app.view = '404';
	}
};

const renderModule = () => {
	let module = getModule();

	module.object.init(($module, title, view) => {
		$app.find('header .page-title .container-fluid').html(title);
		$app.find('main > .container-fluid > div:not(.modal)').addClass('d-none');
		if (!_.isEmpty($module) && $.contains(document, _.first($module))) {
			$module.removeClass('d-none');
			return true;
		}
		
		$app.find('main > .container-fluid').append(view);
		return false;
	});
	
	$.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
	
	function getModule() {
		if (hasModule()) {
			return modules.getList()[app.controller][app.view];
		}

		return modules.getList().errors['404'];
	}
};

init();
