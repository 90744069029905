import Auth from 'store/auth';
import Node from 'store/node';

let subscription = null;
let controller;

const getStatus = () => {
	return Auth.fetchStatus();
};

const authenticate = (credentials) => {
	return Auth.authenticate(credentials);
};

const verifyCode = (data) => {
	return Auth.verifyCode(data);
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	if (_.isNull(state.health)) {
		return;
	}
	
	controller.handleSubscription(state);
	
	if (!(state.health?.state ?? false)) {
		return;
	}
	
	if (state.health.state.toLowerCase() === 'ready') {
		unsubscribe();
	}
};

const unsubscribe = () => {
	if (subscription) {
		subscription.unsubscribe();
		subscription = null;
		Node.stopPollingHealth();
	}
};

const subscribe = (parent) => {
	controller = parent;
	subscription = Node.subscribeToProperties(['health'], handleSubscription);
	
	Node.initHealthState();
	Node.startPollingHealth();
};

export {
	getStatus,
	authenticate,
	verifyCode,
	subscribe
};
