import domElementPath from 'dom-element-path';

const elementPath = (element) => {
	if (element === undefined) {
		return '';
	}
	
	element.className = _.trim(element.className, ' .');
	function trimParent(el) {
		let parent = _.first($(el).parent());
		if (parent) {
			parent.className = _.trim(parent.className, ' .');
			trimParent(parent);
		}
	}
	trimParent(element);
	return domElementPath(element);
};

export default elementPath;
export {
	elementPath
};
