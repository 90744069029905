import form from 'views/alert/forms/new.html';
import * as service from 'alert/service';
import * as taskService from 'task/service';

let $form;

const getForm = () => {
	return $form;
};

const setForm = () => {
	$form = $('#contact-point-new');
};

const restoreModal = (event) => {
	getForm().formValidation('removeField', getForm().find('.recipient:not(:first-of-type) .address'));
	getForm().find('.recipient:not(:first-of-type)').remove();
	getForm().find('.recipient .remove').addClass('invisible');
};

const create = (event) => {
	event.preventDefault();
	let data = serializeJSON(_.first(getForm()), { useIntKeysAsArrayIndex: true });
	
	getForm().beforeSubmit();
	service.createContactPoint(data)
		.then((task) => {
			getForm().afterSubmit();
			
			let notification = app.notifier.add({ text: app.t('alert:phrases.creatingContactPoint', { name: data.name }), type: 'info', sticker: false, closer: false, closerHover: false });
			taskService.wait(task.jobId, (task) => {
				notification.hide();
				if (task.status.toLowerCase() === 'failed') {
					app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.couldNotCreateContactPoint', { name: data.name }), type: 'error' });
				}
				if (task.status.toLowerCase() === 'succeeded') {
					app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.contactPointCreated', { name: data.name }), type: 'success' });
				}
			});
		})
		.catch((error) => {
			getForm().invalidate(error, app.t('alert:phrases.couldNotCreateContactPoint', { name: data.name }));
		});
};

const addRecipient = (event) => {
	let $recipient = getForm().find('.recipient:first-of-type').clone();
	getForm().find('.recipients').append($recipient);
	getForm().find('.recipient .remove').removeClass('invisible');
	let $address = $recipient.find('.address');
	$address.val('');
	getForm().formValidation('addField', $address);
	$address.focus();
};

const removeRecipient = (event) => {
	event.preventDefault();
	let $recipient = $(event.currentTarget).closest('.recipient');
	getForm().formValidation('removeField', $recipient.find('.address'));
	$recipient.remove();
	let $lastRecipient = getForm().find('.recipient');
	if (_.size($lastRecipient) === 1) {
		$lastRecipient.find('.remove').addClass('invisible');
	}
};

const init = () => {
	$app.append(app.render(form));
	
	setForm();
	getForm()
		.formValidation({
			fields: {
				name: {
					selector: '.name',
					validators: {
						notEmpty: {}
					}
				},
				recipient: {
					selector: '.address',
					validators: {
						notEmpty: {},
						emailAddress: { message: app.t('validator:phrases.invalidEmail') },
						callback: {
							message: app.t('validator:phrases.alreadyExists'),
							callback: (value) => {
								let values = _.filter(_.map(getForm().find('.address'), (input) => { return input.value; }), (inputValue) => { return inputValue === value; });
								if (_.size(values) > 1) {
									return false;
								}
								
								return true;
							}
						}
					}
				}
			}
		})
		.on('success.form.fv', create)
		.on('hidden.bs.modal', restoreModal)
		.on('click', '.new', addRecipient)
		.on('click', '.remove', removeRecipient);
};

export {
	init
};
