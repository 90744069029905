const ripple = ($element) => {
	let $ripple = $element.find('.ripple');
	// create .ripple element if it doesn't exist
	if (_.isEmpty($ripple)) {
		$element.append('<span class="ripple"></span>');
		$ripple = $element.find('.ripple');
	}
	
	// prevent quick double clicks
	$ripple.removeClass('animate');
	
	// set ripple diameter
	if (!$ripple.height() && !$ripple.width()) {
		let d = Math.max($element.outerWidth(), $element.outerHeight());
		$ripple.css({ height: d, width: d });
	}
	
	// get click coordinates
	let top = event.pageY - $element.offset().top - $ripple.height() / 2;
	let left = event.pageX - $element.offset().left - $ripple.width() / 2;
	
	// set ripple position and add class .animate
	$ripple.css({ top: `${top}px`, left: `${left}px` }).addClass('animate');
	
	setTimeout(() => {
		$ripple.removeClass('animate');
	}, 500);
};

$(document.body)
	.on('click', 'button.btn, a.btn', (event) => {
		let $button = $(event.target);
		
		ripple($button);
		
		if ($button.data('loading-text') && !$button.hasClass('dropdown-toggle')) {
			let loadingProgress = 0;
			let words = [app.t('labels.stillWorking'), app.t('labels.mostlyDone'), app.t('labels.aBitMore'), app.t('labels.almostDone')];
			let interval = setInterval(() => {
				if (!$button.is(':visible') || !$button.attr('disabled') || loadingProgress === _.size(words)) {
					clearInterval(interval);
					return;
				}
				
				$button.html(words[loadingProgress]);
				loadingProgress++;
			}, 10000);
		}
	});
