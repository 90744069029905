import Galactus from '../galactus';
import template from './progress.html';

customElements.define('gt-progress', class Progress extends Galactus {
	#slot;
	#template = template;
	#slotAssociation = [];
	#observer;
	
	constructor() {
		super();
		
		this.#observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'attributes') {
					let target = mutation.target;
					let association = _.find(this.#slotAssociation, { element: target });
					association.progress.style.width = `${target.getAttribute('value')}%`;
					let progressBar = association.progress.querySelector('.progress-bar');
					this.#setColor(progressBar, target.getAttribute('color'), target.getAttribute('tone'));
					progressBar.classList.remove('progress-bar-striped', 'progress-bar-animated');
					if (target.hasAttribute('animated')) {
						progressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
					}
				}
			});
		});
		
		this.#render();
		this.#attachEventListeners();
	}
	
	#setColor(progressBar, color, tone) {
		color = color || 'blue';
		tone = tone || '500';
		let classList = progressBar.classList;
		let classesToRemove = Array.from(classList).filter((className) => { return className.startsWith('bd-'); });
		if (classesToRemove.length > 0) {
			classList.remove(...classesToRemove);
		}
		classList.add(`bd-${color}-${tone}`);
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = '<div class="progress-stacked"></div><slot class="d-none"></slot>';
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#slot = this.shadowRoot.querySelector('slot');
		this.#renderSlots();
	}
	
	#renderSlots() {
		let progressStacked = this.shadowRoot.querySelector('.progress-stacked');
		progressStacked.innerHTML = '';
		this.#slot.assignedElements().forEach((element) => {
			if (element.nodeName.toLowerCase() === 'progress') {
				let template = document.createElement('template');
				template.innerHTML = this.#template;
				let progress = template.content.querySelector('.progress');
				progress.style.width = `${element.getAttribute('value')}%`;
				let progressBar = progress.querySelector('.progress-bar');
				this.#setColor(progressBar, element.getAttribute('color'), element.getAttribute('tone'));
				progressBar.classList.remove('progress-bar-striped', 'progress-bar-animated');
				if (element.hasAttribute('animated')) {
					progressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
				}
				progressStacked.appendChild(template.content);
				this.#slotAssociation.push({
					element: element,
					progress: progress
				});
				this.#observer.observe(element, { attributes: true });
			}
		});
	}
	
	#attachEventListeners() {
		this.#slot.addEventListener('slotchange', (event) => {
			this.#slotAssociation = [];
			this.#renderSlots();
		});
	}
	
	setValue(value, progressIndex) {
		progressIndex = progressIndex || 1;
		this.querySelector(`progress:nth-of-type(${progressIndex})`)?.setAttribute('value', value);
		this.#renderSlots();
	}
});
