import i18next from 'i18next';
import resources from './resources';

const changeLanguage = (language) => {
	setLanguage(language);
	i18next.changeLanguage(language);
};

const getLanguage = () => {
	let language = localStorage.getItem('language');
	if (!language) {
		language = 'en';
		setLanguage(language);
	}

	return language;
};

const setLanguage = (language) => {
	localStorage.setItem('language', language);
	app.language = language;
};

const t = function () {
	return i18next.t.apply(null, arguments);
};


i18next
	.init({
		fallbackLng: 'en',
		resources,
		lng: getLanguage(),
		defaultNS: 'common',
		debug: false
	}, (error) => {
		// console.log(error);
	});

export {
	changeLanguage,
	getLanguage,
	t
};
