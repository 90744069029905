import Galactus from '../galactus';
import template from './switch.html';

customElements.define('gt-switch', class Switch extends Galactus {
	#checkbox;
	#initialChecked;
	#initialDisabled;
	#template = template;
	
	static get observedAttributes() {
		return ['variant', 'on-label', 'off-label', 'checked', 'disabled'];
	}
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.#checkbox.checked = this.#initialChecked;
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'variant') {
			this.#setVariant(newValue);
		}
		if (name === 'on-label') {
			this.shadowRoot.querySelector('.on-label').innerHTML = newValue;
		}
		if (name === 'off-label') {
			this.shadowRoot.querySelector('.off-label').innerHTML = newValue;
		}
		if (name === 'checked') {
			this.#checkbox.checked = (newValue !== null);
		}
		if (name === 'disabled') {
			this.#checkbox.disabled = (newValue !== null);
			this.shadowRoot.querySelector('.switch').classList.add('disabled');
		}
	}
	
	restore() {
		this.#checkbox.checked = !this.#checkbox.checked;
		this.#checkbox.disabled = false;
		this.shadowRoot.querySelector('.spinner-border').classList.add('d-none');
	}
	
	start() {
		this.#checkbox.disabled = true;
		this.shadowRoot.querySelector('.spinner-border').classList.remove('d-none');
	}
	
	stop() {
		this.#checkbox.disabled = false;
		this.shadowRoot.querySelector('.spinner-border').classList.add('d-none');
	}
	
	get checked() {
		return this.#checkbox.checked;
	}
	
	set checked(value) {
		this.removeAttribute('checked');
		if (String(value) === 'true') {
			this.toggleAttribute('checked');
		}
	}
	
	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	set variant(value) {
		this.setAttribute('variant', value);
	}
	
	set onLabel(value) {
		this.setAttribute('on-label', value);
	}
	
	set offLabel(value) {
		this.setAttribute('off-label', value);
	}
	
	#setVariant(variant) {
		let classList = this.shadowRoot.querySelector('.switch').classList;
		let classesToRemove = Array.from(classList).filter((className) => { return className !== 'switch'; });
		if (classesToRemove.length > 0) {
			classList.remove(...classesToRemove);
		}
		if (variant) {
			classList.add(`switch-${variant}`);
		}
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#checkbox = this.shadowRoot.querySelector('input');
		this.#initialChecked = this.hasAttribute('checked');
		this.#initialDisabled = this.hasAttribute('disabled');
		this.shadowRoot.querySelector('.switch').classList.toggle('disabled', this.#checkbox.disabled);
		if (!this.hasAttribute('variant')) {
			this.#setVariant();
		}
		if (this.hasAttribute('help-text')) {
			this.shadowRoot.querySelector('label').title = this.getAttribute('help-text');
		}
	}
	
	#attachEventListeners() {
		this.#checkbox.addEventListener('change', (event) => {
			this.dispatchEvent(new event.constructor(event.type, event));
		});
		
		this.shadowRoot.querySelector('.on-label').addEventListener('click', (event) => {
			this.#checkbox.checked = true;
			this.dispatchEvent(new event.constructor('change', event));
		});
		this.shadowRoot.querySelector('.off-label').addEventListener('click', (event) => {
			this.#checkbox.checked = false;
			this.dispatchEvent(new event.constructor('change', event));
		});
		
		this.shadowRoot.querySelectorAll('.switch').forEach((trigger) => {
			new bootstrap.Tooltip(trigger);
		});
	}
});
