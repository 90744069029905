const handle = (error, callback, restore) => {
	callback = (callback || function () {});
	restore = (restore || function () {});
	
	if (_.includes(_.keys(error.response.headers), 'www-authenticate')) {
		let identityDialog = dialog(app.t('authentication:phrases.verifyIdentity'),
			app.render('confirmIdentity'),
			{
				cancel: {
					label: app.t('labels.cancel'),
					className: 'btn-outline-secondary cancel',
					callback: (event) => {
						restore();
					}
				},
				delete: {
					label: app.t('authentication:labels.verify'),
					className: 'btn-danger',
					callback: (event) => {
						callback($(event.delegateTarget).find('.password').val());
					}
				}
			},
			{
				id: 'identity-checker'
			}
		);
		identityDialog.find('.password').keypress((event) => {
			if (event.code.toLowerCase() !== 'enter') {
				return;
			}
			
			identityDialog.find('.btn.btn-danger').trigger('click');
		});
		return false;
	}

	return true;
};

export {
	handle
};
