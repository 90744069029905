import inflection from 'lodash-inflection';
import deepdash from 'deepdash';

deepdash(_);

_.mixin(inflection);

_.mixin({
	toReadableSize: (value, decimals) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		if (decimals === undefined) {
			decimals = decimals || 1; // needs to be inside the if, otherwise when the value is 0 it will evaluate to falsy and set itself to default value 1
		}
		
		let a, b, c, d;
		return `${(a=Math, b=a.log, c=1024, d=b(value) / b(c) | 0, value / a.pow(c, d)).toFixed(decimals)}\u00A0${d ? `${('KMGTPEZY'[--d])}B` : 'B'}`;
	},
	toReadableFrequency: (value, decimals) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		if (decimals === undefined) {
			decimals = decimals || 1; // needs to be inside the if, otherwise when the value is 0 it will evaluate to falsy and set itself to default value 1
		}
		
		let a, b, c, d;
		return `${(a=Math, b=a.log, c=1024, d=b(value) / b(c) | 0, value / a.pow(c, d)).toFixed(decimals)}\u00A0${d ? `${('KMGTPEZY'[--d])}Hz` : 'Hz'}`;
	},
	toReadableSpeed: (value) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		if (this < 1000000000) {
			return `${Math.round(value / 1000000)}\u00A0Mb/s`;
		}
		
		return `${Math.round(value / 1000000000)}\u00A0Gb/s`;
	},
	toSizeUnit: (value, unit, decimals) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		if (decimals === undefined) {
			decimals = decimals || 1; // needs to be inside the if, otherwise when the value is 0 it will evaluate to falsy and set itself to default value 1
		}
		
		let unitValues = { 'B': 0, 'KB': 1, 'MB': 2, 'GB': 3, 'TB': 4 , 'PB': 5, 'EB': 6, 'ZB': 7, 'YB': 8 };
		return (value / Math.pow(1024, unitValues[unit])).toFixed(decimals);
	},
	toFrequencyUnit: (value, unit, decimals) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}

		if (value < 0) {
			return 'unknown';
		}

		if (decimals === undefined) {
			decimals = decimals || 1; // needs to be inside the if, otherwise when the value is 0 it will evaluate to falsy and set itself to default value 1
		}

		let unitValues = { 'Hz': 0, 'KHz': 1, 'MHz': 2, 'GHz': 3, 'THz': 4 , 'PHz': 5, 'EHz': 6, 'ZHz': 7, 'YHz': 8 };
		return (value / Math.pow(1024, unitValues[unit])).toFixed(decimals);
	},
	toBytes: (value, unit) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		let unitValues = { 'B': 0, 'KB': 1, 'MB': 2, 'GB': 3, 'TB': 4 , 'PB': 5, 'EB': 6, 'ZB': 7, 'YB': 8 };
		return (value * Math.pow(1024, unitValues[unit]));
	},
	toPercentage: (value, decimals) => {
		value = _.toNumber(value);
		if (isNaN(value)) {
			console.error(`${value} is not a number`);
			return '<span class="text-danger">error</span>';
		}
		
		if (value < 0) {
			return 'unknown';
		}
		
		if (decimals === undefined) {
			decimals = decimals || 0; // needs to be inside the if, otherwise when the value is 0 it will evaluate to falsy and set itself to default value 1
		}
		
		value = value > 100 ? 100 : value;
		return `${value.toFixed(decimals)}%`;
	},
	getSizeUnit: (value) => {
		let unitValues = { 'B': 0, 'KB': 1, 'MB': 2, 'GB': 3, 'TB': 4 , 'PB': 5, 'EB': 6, 'ZB': 7, 'YB': 8 };
		return _.findKey(unitValues, (power) => {
			return _.inRange(value, Math.pow(1024, power), Math.pow(1024, power + 1));
		});
	}
});
