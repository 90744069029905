class Clock {
	#clockPlaceholder = null;
	#referenceDateTime = null;
	#serverTimeOffsetInMs = 0;
	
	constructor() {
		this.#setElement('header .clock a');
		this.#calculateServerOffsetInMs();
		this.#tick();
	}
	
	#setElement(element) {
		this.#clockPlaceholder = document.querySelector(element);
		return this;
	}
	
	#calculateServerOffsetInMs() {
		let defaultServerOffset = 0;
		if (_.isNull(this.#referenceDateTime)) {
			return defaultServerOffset;
		}
		
		if (!this.#referenceDateTime.isValid()) {
			return defaultServerOffset;
		}
		
		return this.#referenceDateTime.valueOf() - moment().valueOf();
	}
	
	#tick() {
		this.#displayCurrentTime();
		setTimeout(this.#tick.bind(this), 999);
		return this;
	}
	
	#displayCurrentTime(dateTimeFormat) {
		dateTimeFormat = dateTimeFormat || 'HH:mm:ss';
		if (this.#clockPlaceholder) {
			this.#clockPlaceholder.innerHTML = this.getCurrentTime().format(dateTimeFormat);
		}
		
		return this;
	}
	
	setReferenceDateTime() {
		this.#referenceDateTime = moment.tz(product.dateTime.date, product.dateTime.timezone);
		this.#serverTimeOffsetInMs = this.#calculateServerOffsetInMs();
		return this;
	}
	
	getReferenceDateTime() {
		return this.#referenceDateTime;
	}
	
	getCurrentTime() {
		if (_.isNull(this.getReferenceDateTime())) {
			return moment();
		}
		
		return moment().tz(product.dateTime.timezone).add({ milliseconds: this.#serverTimeOffsetInMs });
	}
}

export default Clock;
