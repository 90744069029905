import form from 'views/system/forms/shutdown.html';

let $form;

const getForm = () => {
	return $form;
};

const setForm = () => {
	$form = $('#shutdown');
};

const shutdown = (event) => {
	event.preventDefault();
	
	confirm(app.t('system:phrases.confirmShutdown'), callback, app.t('labels.shutdown'));
	
	function callback(result) {
		if (result === false) {
			return;
		}
		
		getForm().beforeSubmit();
		window.location = '/system/shutdown';
	}
};

const init = () => {
	$app.append(app.render(form));

	setForm();
	getForm()
		.on('submit', shutdown);
};

export {
	init
};
