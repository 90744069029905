import elementPath from 'common/element_path';

$(document.body)
	.on('click', 'a[data-controller], gt-notifier', (event) => {
		let $link;
		if (event.currentTarget.nodeName.includes('-')) {
			$link = $(event.currentTarget.shadowRoot).find('a[data-controller]');
			if (_.isEmpty($link)) {
				return false;
			}
		} else {
			$link = $(event.currentTarget);
		}
		if ($link.attr('target') || _.startsWith($link.attr('href'), '#')) {
			return false;
		}

		event.preventDefault();
		let controller = $link.data('controller');
		let row = $link.data('row') || '';
		let search = $link.data('search') || '';
		let $container = $(`#${controller}`);
		let card = _.first($link.parents('.card'));
		
		app.history.push({ pathname: $link.attr('href'), hash: location.hash });
		
		if (!_.isEmpty($container)) {
			$container.find(`.list tr[data-row="${row}"]`).trigger('click');
		} else {
			$.table.add(controller, row);
			$.table.expand(controller);
		}
		$.table.search(controller, search);
		
		app.bond.logEvent(app.bond.CLICK_LINK, {
			id: _.kebabCase(`${app.controller}${app.view !== 'index' ? `-${app.view}` : ''}`),
			href: $link.attr('href'),
			parent: (card ? elementPath(card) : false),
			controller: controller,
			row: row
		});
	})
	.on('click', 'a[target="_blank"]', (event) => {
		let $link = $(event.currentTarget);
		let card = _.first($link.parents('.card'));
		
		app.bond.logEvent(app.bond.CLICK_LINK, {
			id: _.kebabCase(`${app.controller}${app.view !== 'index' ? `-${app.view}` : ''}`),
			href: $link.attr('href'),
			parent: (card ? elementPath(card) : false)
		});
	})
	.on('change.bootstrapSwitch', '.switch', (event) => {
		let $link = $(event.currentTarget);
		let card = _.first($link.parents('.card'));

		app.bond.logEvent(app.bond.TOGGLE_SWITCH, {
			id: _.kebabCase(`${app.controller}${app.view !== 'index' ? `-${app.view}` : ''}`),
			checked: event.target.checked,
			parent: (card ? elementPath(card) : false)
		});
	});
