import navigationPartial from 'views/common/navigation.html';

export default function navigation() {
	let items = [
		{ label: app.t('labels.dashboard'), icon: product.isCloudGate ? 'fa-cloud' : 'fa-tachometer-alt', controller: 'dashboard' },
		{ label: app.t('labels.tasks'), icon: 'fa-tasks', controller: 'tasks', hasCounterBadge: true },
		{ label: app.t('labels.virtualization'), icon: 'fa-window-alt', controller: 'virtualization',
			items: [
				{ label: app.t('labels.hosts'), controller: 'hosts' },
				{ label: app.t('labels.virtualMachines'), controller: 'virtualMachines', view: 'index' },
				{ label: app.t('labels.imageRepository'), controller: 'imageRepository' }
			]
		},
		{ label: app.t('labels.protection'), icon: 'fa-shield-check', controller: 'protection',
			items: [
				{ label: app.t('labels.slaPolicies'), controller: 'sla' },
				{ label: app.t('labels.replicationTargets'), controller: 'replicationTargets' },
				{ label: app.t('labels.replicas'), controller: 'virtualMachines', view: 'replicas' }
			]
		},
		{ label: app.t('labels.system'), icon: 'fa-cog', controller: 'system',
			items: [
				{ label: app.t('labels.networkConnections'), controller: 'network', 'view': 'adapters' },
				{ label: app.t('labels.drives'), controller: 'disks' },
				{ label: app.t('labels.pools'), controller: 'pools' },
				{ label: app.t('labels.users'), controller: 'users' },
				{ label: app.t('labels.security'), controller: 'system', view: 'security' },
				{ label: app.t('labels.alerts'), controller: 'alerts' },
				{ label: app.t('labels.dateTime'), controller: 'time' },
				{ label: app.t('labels.softwareUpdates'), controller: 'firmware', hasCounterBadge: true },
				{ label: app.t('labels.about'), controller: 'system', view: 'about' }
			]
		}
	];
	
	const getItems = () => {
		items = _.filterDeep(items, (value) => {
			if (!app.user.isSuper) {
				if (
					value.controller === 'users' ||
					(value.controller === 'system' && value.view === 'security')
				) {
					return false;
				}
			}
			
			if (product.isCloudGate) {
				if (value.controller === 'virtualization') {
					return { skipChildren: true };
				}

				if (
					value.controller === 'sla' ||
					value.controller === 'disks' ||
					value.controller === 'pools' ||
					value.controller === 'firmware' ||
					value.controller === 'time' ||
					(value.controller === 'network' && value.view === 'adapters') ||
					(value.controller === 'system' && value.view === 'about')
				) {
					return false;
				}
			}

			return true;
		}, { childrenPath: 'items' });
		
		return items;
	};
	
	const render = () => {
		return app.render(navigationPartial, { items: getItems() });
	};
	
	return {
		render
	};
}
