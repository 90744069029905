import Node from 'store/node';

let subscription = null;
let callbackCollection = [];

const getFirmware = () => {
	return Node.getFirmware();
};

const checkAvailableUpdates = () => {
	return Node.checkAvailableUpdates();
};

const getUpdates = () => {
	return Node.getUpdates();
};

const getUpdate = () => {
	return Node.getUpdate();
};

const isPollingUpdates = () => {
	return Node.isPollingUpdates();
};

const startPollingUpdates = () => {
	return Node.startPollingUpdates();
};

const update = (toRelease) => {
	return Node.update(toRelease);
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	_.each(callbackCollection, (callback) => {
		callback(state);
	});
};

const unsubscribe = () => {
	if (subscription) {
		subscription.unsubscribe();
		subscription = null;
	}
};

const subscribe = (callbacks) => {
	callbackCollection = _.concat(callbackCollection, callbacks);
	unsubscribe();
	subscription = Node.subscribeToProperties(['software', 'isCheckingForUpdates', 'isPollingUpdates', 'central'], handleSubscription);
};

export {
	getFirmware,
	checkAvailableUpdates,
	getUpdates,
	getUpdate,
	update,
	isPollingUpdates,
	startPollingUpdates,
	subscribe,
	unsubscribe
};
