import form from 'views/system/forms/restart.html';

let $form;

const getForm = () => {
	return $form;
};

const setForm = () => {
	$form = $('#restart');
};

const restart = (event) => {
	event.preventDefault();
	
	confirm(app.t('system:phrases.confirmRestart'), callback, app.t('labels.restart'));
	
	function callback(result) {
		if (result === false) {
			return;
		}
		
		getForm().beforeSubmit();
		window.location = '/system/restart';
	}
};

const init = () => {
	$app.append(app.render(form));

	setForm();
	getForm()
		.on('submit', restart);
};

export {
	init
};
