import Galactus from '../galactus';
import template from './select.html';

customElements.define('gt-select', class Select extends Galactus {
	#select;
	#initialDisabled;
	#initialOptions;
	#slot;
	#template = template;
	#observer;
	#dropdown;
	#language;
	
	static get observedAttributes() {
		return ['label', 'disabled', 'error'];
	}

	constructor() {
		super();
		
		this.#observer = new MutationObserver(() => {
			this.#renderSelection();
		});

		this.#render();
		this.#attachEventListeners();
	}

	formResetCallback() {
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
		this.removeAttribute('error');
		this.#select.innerHTML = this.innerHTML = this.#initialOptions;
		this.#renderOptions();
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'label') {
			this.#setLabel(newValue);
		}
		if (name === 'disabled') {
			this.#select.disabled = (newValue !== null);
			this.shadowRoot.querySelector('.dropdown-toggle').disabled = (newValue !== null);
		}
		if (name === 'error') {
			this.shadowRoot.querySelector('.invalid-feedback.component').innerHTML = newValue;
			if (newValue === '' || newValue === null) {
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.add('d-none');
				this.shadowRoot.querySelector('.form-group').classList.remove('has-error');
			} else {
				this.shadowRoot.querySelector('.form-group').classList.add('has-error');
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.remove('d-none');
			}
		}
	}

	get name() {
		return this.getAttribute('name');
	}

	get type() {
		return (this.hasAttribute('multiple') ? 'select-multiple' : 'select-one');
	}

	get nodeName() {
		return 'SELECT';
	}

	get options() {
		return this.#select.options;
	}

	set options(value) {
		this.#select.innerHTML = value;
		this.#renderOptions();
	}

	get selectedIndex() {
		return this.#select.selectedIndex;
	}

	set selectedIndex(value) {
		this.#select.selectedIndex = value;
	}

	get value() {
		return Array.from(this.#select.options).find((option) => { return option.selected; })?.value;
	}
	
	set value(value) {
		let hasOptionSelected = false;
		Array.from(this.#select.options).forEach((option) => {
			option.selected = (option.value === value.toString());
			if (option.selected) {
				hasOptionSelected = true;
			}
		});
		if (!hasOptionSelected) {
			this.#select.selectedIndex = -1;
		}
		this.#renderOptions();
		this.#select.dispatchEvent(new Event('change', { bubbles: true }));
	}

	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	get error() {
		return this.getAttribute('error');
	}
	
	set error(value) {
		if (typeof value === 'string' || typeof value === 'number') {
			this.setAttribute('error', value);
		} else {
			this.removeAttribute('error');
		}
	}
	
	#setLabel(value) {
		let label = value;
		if (this.hasAttribute('help-text')) {
			label += `<span class="help-inline ms-1" data-bs-toggle="tooltip" title="${this.getAttribute('help-text')}"><i class="fas fa-question-circle text-body-secondary"></i></span>`;
		}
		this.shadowRoot.querySelector('label').innerHTML = label;
	}

	#renderActionsBox() {
		let buttonsGroup = document.createElement('div');
		buttonsGroup.classList.add('btn-group', 'btn-group-sm', 'w-100', 'mb-1');
		let selectAllButton = document.createElement('button');
		selectAllButton.classList.add('btn', 'btn-light', 'select-all');
		selectAllButton.innerHTML = this.#language.selectAll;
		let deselectAllButton = document.createElement('button');
		deselectAllButton.classList.add('btn', 'btn-light', 'deselect-all');
		deselectAllButton.innerHTML = this.#language.deselectAll;
		buttonsGroup.appendChild(selectAllButton);
		buttonsGroup.appendChild(deselectAllButton);
		this.#dropdown._menu.prepend(buttonsGroup);
		this.#dropdown._menu.querySelectorAll('.btn').forEach((button) => {
			button.addEventListener('click', (event) => {
				Array.from(this.#select.options).forEach((option) => {
					option.selected = button.classList.contains('select-all');
				});
				this.#select.dispatchEvent(new event.constructor('change', event));
			});
		});
	}

	#render() {
		this.#setLanguage();
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));

		this.#select = this.shadowRoot.querySelector('select');
		this.#select.multiple = this.hasAttribute('multiple');
		this.#initialDisabled = this.hasAttribute('disabled');

		this.shadowRoot.querySelector('.selection').innerHTML = this.#language.nothingSelected;

		if (this.hasAttribute('gapless')) {
			this.shadowRoot.querySelector('.form-group').classList.add('mb-0');
		}

		this.#initialOptions = this.innerHTML;
		this.#slot = this.shadowRoot.querySelector('slot');
		this.#renderOptions();
	}

	#setLanguage() {
		this.#language = {
			deselectAll: this.getAttribute('deselect-all') ?? 'Deselect all',
			itemSelected: this.getAttribute('item-selected') ?? '{0} selected',
			noResultsMatch: this.getAttribute('no-results-match') ?? 'No results match {0}',
			nothingSelected: this.getAttribute('nothing-selected') ?? 'Nothing selected',
			selectAll: this.getAttribute('select-all') ?? 'Select all'
		};
	}

	#renderOptions() {
		const clickOutside = this.#handleClickOutside.bind(this);
		this.shadowRoot.querySelectorAll('.dropdown-menu').forEach((trigger) => {
			this.#dropdown = new bootstrap.Dropdown(trigger, {
				autoClose: true,
				reference: 'parent',
				boundary: 'body',
				offset: [-12, 9]
			});
			this.#dropdown._element.addEventListener('show.bs.dropdown', (event) => {
				document.addEventListener('click', clickOutside);
				document.body.appendChild(this.#dropdown._menu);
			});
			this.#dropdown._element.addEventListener('shown.bs.dropdown', (event) => {
				this.#dropdown._menu.style.minWidth = `${this.shadowRoot.querySelector('.form-control').offsetWidth - 2}px`;
				let input = this.#dropdown._menu.querySelector('input');
				if (input) {
					input.focus();
				}
				this.#dropdown._menu.querySelector('.active')?.scrollIntoView({ block: 'center', inline: 'nearest' });
			});
			this.#dropdown._element.addEventListener('hide.bs.dropdown', (event) => {
				document.removeEventListener('click', clickOutside);
			});
			this.#dropdown._element.addEventListener('hidden.bs.dropdown', (event) => {
				this.#dropdown._parent.appendChild(this.#dropdown._menu);
				let input = this.#dropdown._menu.querySelector('input');
				if (input) {
					input.value = '';
					input.dispatchEvent(new Event('change'));
				}
			});
			this.#renderDropdown();
		});
	}

	#renderDropdown() {
		this.#dropdown._menu.innerHTML = '';
		if (this.hasAttribute('liveSearch')) {
			this.#renderLiveSearch();
		}
		this.#select.querySelectorAll(':scope > *').forEach((element, index) => {
			let id = `option-${index}`;
			if (element.nodeName.toLowerCase() === 'option') {
				renderOption.call(this, element, this.#dropdown._menu, id, element.className);
			}
			if (element.nodeName.toLowerCase() === 'optgroup') {
				let dropdownItemContainer = document.createElement('li');
				let dropdownItem = document.createElement('h6');
				dropdownItem.classList.add('dropdown-header');
				dropdownItem.innerHTML = element.getAttribute('label');
				dropdownItemContainer.appendChild(dropdownItem);
				this.#dropdown._menu.appendChild(dropdownItemContainer);
				element.querySelectorAll('option').forEach((element, index) => {
					renderOption.call(this, element, this.#dropdown._menu, `${id}-${index}`, 'ps-2');
				});
			}
		});

		if (this.hasAttribute('multiple') && this.hasAttribute('actionsBox')) {
			this.#renderActionsBox();
		}

		this.#renderSelection();

		function renderOption(option, container, id, classNames) {
			option.setAttribute('id', id);
			let dropdownItemContainer = document.createElement('li');
			dropdownItemContainer.classList.add.apply(dropdownItemContainer.classList, classNames === '' ? [] : classNames?.split(' ') ?? []);
			let dropdownItem = document.createElement('a');
			dropdownItem.setAttribute('id', `dropdown-item-${id}`);
			dropdownItem.classList.add('dropdown-item');
			dropdownItem.classList.add('pe-4');
			if (option.hasAttribute('disabled')) {
				dropdownItem.classList.add('disabled');
			}
			if (option.selected) {
				dropdownItem.classList.add('active');
			}
			dropdownItem.setAttribute('href', '#');
			dropdownItem.innerHTML = option.getAttribute('data-content');
			dropdownItemContainer.appendChild(dropdownItem);
			container.appendChild(dropdownItemContainer);

			dropdownItem.addEventListener('click', (event) => {
				event.preventDefault();
				if (this.#select.multiple === false) {
					this.#select.value = option.value;
				} else {
					Array.from(this.#select.options).forEach((opt) => {
						if (opt.value === option.value) {
							opt.selected = !opt.selected;
						}
					});
				}
				if (this.#select.multiple === false) {
					this.#dropdown.toggle();
				}
				this.#select.dispatchEvent(new event.constructor('change', event));
			});
		}
	}

	#renderSelection() {
		if (this.#select.multiple === false) {
			this.#dropdown._menu.querySelectorAll('.active').forEach((dropdownItem) => {
				dropdownItem.classList.remove('active');
			});
		}
		let selectedOptions = [];
		Array.from(this.#select.querySelectorAll('option')).forEach((option) => {
			let dropdownItem = this.#dropdown._menu.querySelector(`#dropdown-item-${option.getAttribute('id')}`);
			if (option.selected) {
				selectedOptions.push(option);
				dropdownItem?.classList.add('active');
			} else {
				dropdownItem?.classList.remove('active');
			}
		});
		this.shadowRoot.querySelector('.selection').innerHTML = (selectedOptions.length ? selectedOptions.map((option) => { return option.getAttribute('data-content'); }).join(', ') : this.#language.nothingSelected);
	}

	#renderLiveSearch() {
		let dropdownItemContainer = document.createElement('li');
		dropdownItemContainer.classList.add('position-sticky', 'z-1', 'top-0', 'bg-white');
		dropdownItemContainer.style.cssText = 'margin-left: -4px; margin-right: -4px; padding-left: 4px; padding-right: 4px; padding-top: 6px;';
		let input = document.createElement('input');
		input.classList.add('form-control');
		dropdownItemContainer.appendChild(input);
		this.#dropdown._menu.prepend(dropdownItemContainer);
		input.addEventListener('keyup', filterItems.bind(this));
		input.addEventListener('change', filterItems.bind(this));
		let noResultsItem = document.createElement('li');
		noResultsItem.classList.add('no-results', 'd-none');
		this.#dropdown._element.appendChild(noResultsItem);

		function filterItems(event) {
			let searchText = event.target.value.trim().toLowerCase();
			let dropdownItems = this.#dropdown._menu.querySelectorAll('.dropdown-item');
		
			dropdownItems.forEach((item) => {
				let optionText = item.textContent.trim().toLowerCase();
				if (optionText.includes(searchText)) {
					item.classList.remove('d-none');
				} else {
					item.classList.add('d-none');
				}
			});
			let hasVisibleItems = (this.#dropdown._element.querySelectorAll('.dropdown-item:not(.d-none)').length > 0);
			if (!hasVisibleItems) {
				noResultsItem.innerHTML = this.#language.noResultsMatch.replace('{0}', `"${event.target.value}"`);
			}
			noResultsItem.classList.toggle('d-none', hasVisibleItems);
		}
	}
	
	#handleClickOutside(event) {
		if (!event.composedPath().includes(this.#dropdown._menu) && !event.composedPath().includes(this.#dropdown._parent)) {
			this.#dropdown.hide();
		}
	}

	#attachEventListeners() {
		this.#observer.observe(this.#select, {
			subtree: true,
			attributes: true
		});
		
		this.#slot.addEventListener('slotchange', (event) => {
			this.#select.innerHTML = this.innerHTML;
			this.#renderOptions();
		});

		this.shadowRoot.querySelectorAll('label').forEach((trigger) => {
			new bootstrap.Tooltip(trigger);
		});
		
		this.shadowRoot.querySelector('button').addEventListener('click', (event) => {
			this.#dropdown.toggle();
		});

		this.#select.addEventListener('change', (event) => {
			this.#renderSelection();
			this.dispatchEvent(new event.constructor(event.type, event)); // do not remove, needs to trigger change when selected is changed programmatically
		});
	}
});
