bootstrap.Dropdown.Default.offset = [0, 0];

$(document.body)
	.on('shown.bs.dropdown', '.dropdown', function (event) {
		this.isClosable = false;
	})
	.on('hide.bs.dropdown', '.dropdown', function (event) {
		return this.isClosable;
	})
	.on('click', '.dropdown-menu li.disabled a', (event) => {
		event.preventDefault();
	})
	.on('click', (event) => {
		if (!event.originalEvent) {
			return false;
		}
		_.each($(document.body).find('.dropdown'), (dropdown) => {
			dropdown.isClosable = true;
		});
	})
	.on('click', '.dropdown-menu .language', function (event) {
		event.preventDefault();
		app.changeLanguage($(event.currentTarget).data('language'));
		location.reload();
	});
