import Galactus from '../galactus';
import template from './radio.html';

customElements.define('gt-radio', class Radio extends Galactus {
	#value;
	#initialChoices;
	#slot;
	#template = template;
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.innerHTML = this.#initialChoices;
		this.#renderSlots();
	}
	
	get type() {
		return 'radio';
	}
	
	get nodeName() {
		return 'INPUT';
	}
	
	get value() {
		return this.#value;
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = '<div class="form-group"></div><slot class="d-none"></slot>';
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#initialChoices = this.innerHTML;
		this.#slot = this.shadowRoot.querySelector('slot');
		this.#renderSlots();
	}
	
	#renderSlots() {
		let formGroup = this.shadowRoot.querySelector('.form-group');
		formGroup.innerHTML = '';
		this.#slot.assignedElements().forEach((element) => {
			if (element.nodeName.toLowerCase() === 'choice') {
				let template = document.createElement('template');
				template.innerHTML = this.#template;
				let radio = template.content.querySelector('input[type="radio"]');
				if (this.hasAttribute('name')) {
					radio.name = this.getAttribute('name');
				}
				radio.value = element.getAttribute('value');
				radio.checked = element.hasAttribute('checked');
				radio.disabled = element.hasAttribute('disabled');
				if (radio.checked) {
					this.#value = radio.value;
				}
				template.content.querySelector('label').append(element.getAttribute('label'));
				formGroup.appendChild(template.content);
			}
		});
		
		this.shadowRoot.querySelectorAll('input[type="radio"]').forEach((radio) => {
			radio.addEventListener('change', (event) => {
				this.#value = event.currentTarget.value;
				this.dispatchEvent(new event.constructor(event.type, event));
			});
		});
	}
	
	#attachEventListeners() {
		this.#slot.addEventListener('slotchange', (event) => {
			this.#renderSlots();
		});
	}
});
