import httpClient from '@syneto/http-client';

let isRedirecting = false;
httpClient.instance.defaults.baseURL = '/';
httpClient.instance.defaults.headers = {
	'X-Requested-With': 'XMLHttpRequest'
};

httpClient.instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (_.includes(_.keys(error?.response?.headers), 'www-authenticate')) {
			if (error.response.headers['www-authenticate'].toLowerCase() !== 'continuous auth required') {
				setTimeout(() => {
					if (_.isEmpty($('#identity-checker'))) {
						app.notifier.add({ text: error.response.headers['www-authenticate'], type: 'error' });
					}
				}, 500);
			}
		}
		
		if (httpClient.isCancel(error) || error?.config?.noIntercept) {
			return Promise.reject(error);
		}

		if (app.isAuthenticated && error.response && _.includes([401], error.response.status) && !_.includes(_.keys(error.response.headers), 'www-authenticate')) { // session expired
			if (isRedirecting) {
				return;
			}

			isRedirecting = true;
			window.location = `/login${app.history.location.hash}`;
			return;
		}
		
		return Promise.reject(error);
	}
);

window.httpClient = httpClient;
