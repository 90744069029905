import Monitoring from '../store/monitoring';

let callbackCollection = [];

const fetchEmailProviders = () => {
	return Monitoring.fetchEmailProviders();
};

const fetchContactPoints = () => {
	return Monitoring.fetchContactPoints();
};

const getEmailProviders = () => {
	return Monitoring.getEmailProviders();
};

const getContactPoints = () => {
	return Monitoring.getContactPoints();
};

const sendTestEmail = (configuration) => {
	return Monitoring.sendTestEmail(configuration);
};

const saveSmtpServer = (configuration) => {
	if (_.isEmpty(configuration.uid)) {
		return Monitoring.addSmtpServer(configuration);
	} else {
		return Monitoring.updateSmtpServer(configuration);
	}
};

const createContactPoint = (contactPoint) => {
	return Monitoring.createContactPoint(contactPoint);
};

const updateContactPoint = (contactPoint) => {
	return Monitoring.updateContactPoint(contactPoint);
};

const deleteContactPoint = (uid) => {
	return Monitoring.deleteContactPoint(uid);
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	_.each(callbackCollection, (callback) => {
		callback(state);
	});
};

const subscribe = (callbacks) => {
	callbackCollection = _.concat(callbackCollection, callbacks);
	
	Monitoring.subscribeToProperties(['emailProviders', 'contactPoints'], handleSubscription);
	
	Monitoring.getEmailProviders();
	Monitoring.getContactPoints();
};

export {
	subscribe,
	fetchEmailProviders,
	fetchContactPoints,
	getEmailProviders,
	getContactPoints,
	sendTestEmail,
	saveSmtpServer,
	createContactPoint,
	updateContactPoint,
	deleteContactPoint
};
