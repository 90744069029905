import Store, { INIT_STATE } from './store';

const SET_POLLING_TASKS = 'SET_POLLING_TASKS';
const GET_ONGOING_TASKS_COUNT = 'GET_ONGOING_TASKS_COUNT';
const GET_TASKS = 'GET_TASKS';
const GET_RECOVERY_TASKS = 'GET_RECOVERY_TASKS';
const GET_MIGRATE_TASKS = 'GET_MIGRATE_TASKS';
const GET_TASK = 'GET_TASK';
const GET_CATEGORIES_TREE = 'GET_CATEGORIES_TREE';

class Task extends Store {
	constructor() {
		const initialState = {
			ongoingTasksCount: null,
			recoveryTasks: null,
			migrationTasks: null,
			taskCategoriesTree: null,
			tasks: null,
			task: null,
			isPollingTasks: false
		};
		super();
		this.tasksPollingTimeout = 3000;
		this.virtualMachineMigrationTasksPollingTimeout = 3000;
		this.tasksRequests = [];
		this.remainingTasksPollingRetries = null;
		this.pollingOngoingTasksCount = null;
		this.filters = {
			limit: 1999
		};
		
		this.setState(initialState, INIT_STATE);

		this.subscribeToProperties(['auth', 'software', 'central', 'isRestarting', 'isShuttingDown'], (store) => {
			let { state } = store;
			if ((state?.auth ?? false) === false || (state?.software?.update ?? false) === false || (state?.central ?? false) === false) {
				return;
			}
			
			if (!state.isRestarting && !state.isShuttingDown && state.auth.isAuthenticated && state.central.isActivated && (_.isEmpty(state.software.update) || _.isEmpty(state.software.update.job) || state.software.update.job.isAcknowledged)) {
				this.startPollingOngoingTasksCount();
				this.startPollingVirtualMachineMigrationTasks();
				this.fetchTaskCategoriesTree();
			} else {
				this.stopPollingOngoingTasksCount();
				this.stopPollingVirtualMachineMigrationTasks();
			}
		});
		
		this.stateWithPropertyChanges.subscribe((store) => {
			if (!store) {
				return;
			}
			
			let stateChanges = store.stateChanges;
			if (!_.isEmpty(stateChanges.tasks)) {
				this.tasksPollingTimeout = 3000;
			}
			if (!_.isEmpty(stateChanges.migrationTasks)) {
				this.virtualMachineMigrationTasksPollingTimeout = 3000;
			}
		});
	}

	startPolling() {
		if (this.pollingAllTasks) {
			clearTimeout(this.pollingAllTasks);
			this.pollingAllTasks = null;
		}
		
		if (!_.isEmpty(this.tasksRequests)) {
			httpClient.cancelRequests(this.tasksRequests);
			this.tasksRequests = [];
		}

		if (this.getStateProperty('isRestarting') !== true && this.getStateProperty('isShuttingDown') !== true && this.getStateProperty('isUpdating') !== true) {
			this.setState({ isPollingTasks: true }, SET_POLLING_TASKS);
			this.tasksRequests = [
				this.get(
					'/api/protection/jobs',
					{
						params: this.filters,
						paramsSerializer: {
							serialize: httpClient.serializeParamsWithArrayRepeat
						}
					}
				)
			];
			this.fetchTasks()
				.then(poll.bind(this))
				.catch(poll.bind(this));
		} else {
			this.setState({ isPollingTasks: false }, SET_POLLING_TASKS);
			this.tasksRequests = [];
			poll();
		}

		function poll() {
			if (this.remainingTasksPollingRetries === 0) {
				this.stopPollingTasks();
				return;
			}
			
			this.pollingAllTasks = setTimeout(this.startPolling.bind(this), this.tasksPollingTimeout);
			this.tasksPollingTimeout *= 2;
			if (this.tasksPollingTimeout > 60000) {
				this.tasksPollingTimeout = 60000;
			}
		}
	}
	
	stopPollingTasks() {
		if (!_.isEmpty(this.tasksRequests)) {
			httpClient.cancelRequests(this.tasksRequests);
			this.tasksRequests = [];
		}
		
		if (this.pollingAllTasks) {
			clearTimeout(this.pollingAllTasks);
			this.pollingAllTasks = null;
		}
		
		this.setState({ isPollingTasks: false }, SET_POLLING_TASKS);
	}

	startPollingVirtualMachineRecoveryTasks() {
		if (!_.isEmpty(this.recoveryTasksRequest)) {
			httpClient.cancelRequests(this.recoveryTasksRequest);
			this.recoveryTasksRequest = null;
		}

		if (this.pollingRecoveryTasks) {
			clearTimeout(this.pollingRecoveryTasks);
			this.pollingRecoveryTasks = null;
		}

		if (this.getStateProperty('isRestarting') !== true && this.getStateProperty('isShuttingDown') !== true && this.getStateProperty('isUpdating') !== true) {
			this.recoveryTasksRequest = this.fetchRecoveryTasks()
				.then(poll.bind(this))
				.catch((error) => {
					if (!httpClient.isCancel(error)) {
						poll.call(this);
					}
				});
		} else {
			this.recoveryTasksRequest = null;
			poll();
		}

		function poll() {
			this.pollingRecoveryTasks = setTimeout(this.startPollingVirtualMachineRecoveryTasks.bind(this), 3000);
		}
	}

	stopPollingVirtualMachineRecoveryTasks() {
		if (!_.isEmpty(this.recoveryTasksRequest)) {
			httpClient.cancelRequests(this.recoveryTasksRequest);
			this.recoveryTasksRequest = null;
		}

		if (this.pollingRecoveryTasks) {
			clearTimeout(this.pollingRecoveryTasks);
			this.pollingRecoveryTasks = null;
		}
	}

	startPollingVirtualMachineMigrationTasks() {
		if (!_.isEmpty(this.migrationTasksRequest)) {
			httpClient.cancelRequests(this.migrationTasksRequest);
			this.migrationTasksRequest = null;
		}

		if (this.pollingMigrationTasks) {
			clearTimeout(this.pollingMigrationTasks);
			this.pollingMigrationTasks = null;
		}

		if (this.getStateProperty('isRestarting') !== true && this.getStateProperty('isShuttingDown') !== true && this.getStateProperty('isUpdating') !== true) {
			this.migrationTasksRequest = this.fetchMigrationTasks()
				.then(poll.bind(this))
				.catch((error) => {
					if (!httpClient.isCancel(error)) {
						poll.call(this);
					}
				});
		} else {
			this.migrationTasksRequest = null;
			poll();
		}

		function poll() {
			if (this.remainingMigrationTasksPollingRetries === 0) {
				this.stopPollingVirtualMachineMigrationTasks();
				return;
			}

			this.pollingMigrationTasks = setTimeout(this.startPollingVirtualMachineMigrationTasks.bind(this), this.virtualMachineMigrationTasksPollingTimeout);
			this.virtualMachineMigrationTasksPollingTimeout *= 2;
			if (this.virtualMachineMigrationTasksPollingTimeout > 60000) {
				this.virtualMachineMigrationTasksPollingTimeout = 60000;
			}
		}
	}

	stopPollingVirtualMachineMigrationTasks() {
		if (!_.isEmpty(this.migrationTasksRequest)) {
			httpClient.cancelRequests(this.migrationTasksRequest);
			this.migrationTasksRequest = null;
		}

		if (this.pollingMigrationTasks) {
			clearTimeout(this.pollingMigrationTasks);
			this.pollingMigrationTasks = null;
		}

	}

	startPollingOngoingTasksCount() {
		if (this.pollingOngoingTasksCount) {
			return;
		}
		
		this.pollingOngoingTasksCount = httpClient.poll(
			'/api/protection/jobs/count-ongoing',
			{
				multiplier: 1,
				minTimeout: 5000
			},
			(response) => {
				this.setState({ ongoingTasksCount: response.data.jobs }, GET_ONGOING_TASKS_COUNT);
			},
			(error) => {
				this.setState({ ongoingTasksCount: false }, GET_ONGOING_TASKS_COUNT);
			}
		);
	}
	
	stopPollingOngoingTasksCount() {
		if (this.pollingOngoingTasksCount) {
			this.pollingOngoingTasksCount.cancel();
			this.pollingOngoingTasksCount = null;
		}
	}

	fetchTasks() {
		if (_.isEmpty(this.tasksRequests)) {
			return this.createPromise(null);
		}
		
		return Promise.all(this.tasksRequests)
			.then(([response]) => {
				let tasks = _.filter(response.data, (task) => {
					return _.isNull(task.parentId);
				});
				
				this.remainingTasksPollingRetries = null;
				this.setState({ tasks: tasks }, GET_TASKS);
				return tasks;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				if (_.isNull(this.remainingTasksPollingRetries)) {
					this.remainingTasksPollingRetries = 4;
				} else if (this.remainingTasksPollingRetries > 0) {
					this.remainingTasksPollingRetries--;
				}
				
				this.setState({ tasks: false }, GET_TASKS);
				throw error;
			});
	}

	fetchRecoveryTasks() {
		let recoveryTasks;
		let jobQueryParams = new URLSearchParams();
		jobQueryParams.append('types', 'archive_create');
		jobQueryParams.append('types', 'iso_create');
		jobQueryParams.append('limit', 9999999999);

		return this.get(
			'/api/protection/jobs',
			{
				params: jobQueryParams
			}
		)
			.then((response) => {
				recoveryTasks = _.filter(response.data, (task) => {
					return _.isEmpty(task.parentId);
				});
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				recoveryTasks = false;
			})
			.then(() => {
				this.setState({ recoveryTasks: recoveryTasks }, GET_RECOVERY_TASKS);
				return recoveryTasks;
			});
	}

	fetchMigrationTasks() {
		let migrationTasks;
		let jobQueryParams = new URLSearchParams();
		jobQueryParams.append('hide_nested_jobs', 'false');
		jobQueryParams.append('types', 'vm_migrate');
		jobQueryParams.append('types', 'ingest_vm');
		jobQueryParams.append('statuses', 'running');
		jobQueryParams.append('statuses', 'pending');
		jobQueryParams.append('statuses', 'failed');
		jobQueryParams.append('limit', 9999999999);

		return this.get(
			'/api/protection/jobs',
			{
				params: jobQueryParams
			}
		)
			.then((response) => {
				this.remainingMigrationTasksPollingRetries = null;
				migrationTasks = _.map(response.data, (task) => {
					task.children = _.filter(response.data, { parentId: task.id });
					task.children = !_.isEmpty(task.children) ? task.children : null;
					return task;
				});
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				if (_.isNull(this.remainingMigrationTasksPollingRetries)) {
					this.remainingMigrationTasksPollingRetries = 4;
				} else if (this.remainingMigrationTasksPollingRetries > 0) {
					this.remainingMigrationTasksPollingRetries--;
				}
				migrationTasks = false;
			})
			.then(() => {
				this.setState({ migrationTasks: migrationTasks }, GET_MIGRATE_TASKS);
				return migrationTasks;
			});
	}

	fetchTask(id, shouldSetState = true) {
		let task;
		return this.get(`/api/protection/jobs/${id}`)
			.then((response) => {
				task = response.data;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				task = false;
			})
			.then(() => {
				if (shouldSetState) {
					let tasks = _.reject(this.getStateProperty('tasks'), { id: task.id });
					tasks.push(task);
					this.setState({ task: task, tasks: tasks }, GET_TASK);
				}
				
				return task;
			});
	}

	fetchTaskCategoriesTree() {
		let taskCategoriesTree;
		return this.get('/api/protection/jobs/categories-tree')
			.then((response) => {
				taskCategoriesTree = response.data;
			})
			.catch((error) => {
				if (httpClient.isCancel(error)) {
					return;
				}
				
				taskCategoriesTree = false;
			})
			.then(() => {
				this.setState({ taskCategoriesTree: taskCategoriesTree }, GET_CATEGORIES_TREE);
				return taskCategoriesTree;
			});
	}
	
	isPollingTasks () {
		return this.getStateProperty('isPollingTasks');
	}
	
	getTasks(id) {
		if (id === undefined) {
			let tasks = this.getStateProperty('tasks');
			if (!_.isNull(tasks)) {
				return this.createPromise(tasks);
			}
			
			return this.fetchTasks();
		}

		return this.fetchTask(id);
	}

	getTask() {
		let task = this.getStateProperty('task');
		if (!_.isNull(task)) {
			return task;
		}

		return false;
	}

	getTaskCategoriesTree() {
		let taskCategoriesTree = this.getStateProperty('taskCategoriesTree');
		if (!_.isNull(taskCategoriesTree)) {
			return this.createPromise(taskCategoriesTree);
		}

		return this.fetchTaskCategoriesTree();
	}

	acknowledgeTask(id) {
		return this.patch(
			`/api/protection/jobs/${id}`,
			{
				isAcknowledged: true
			}
		)
			.then((response) => {
				return response.data;
			});
	}

	cancelTask(id) {
		return this.patch(
			`/api/protection/jobs/${id}`,
			{
				status: 'CANCELED'
			}
		)
			.then((response) => {
				return response.data;
			});
	}

	retryTask(id) {
		return this.patch(
			`/api/protection/jobs/${id}`,
			{
				status: 'PENDING'
			}
		)
			.then((response) => {
				return response.data;
			});
	}

	cleanupTask(id) {
		return this.patch(
			`/api/protection/jobs/${id}`,
			{
				status: 'CANCELED'
			}
		)
			.then((response) => {
				return response.data;
			});
	}

	wait(id, callback) {
		this.fetchTask(id, false)
			.then((task) => {
				if (!task || _.includes(['pending', 'running'], task.status.toLowerCase())) {
					setTimeout(this.wait.bind(this), 3000, id, callback);
					return;
				}
	
				callback(task);
			});
	}

}

export default new Task();
