import Task from 'store/task';

let taskCallback;
let tasksCallback = [];

const isPollingTasks = () => {
	return Task.isPollingTasks();
};

const getTasks = (id) => {
	return Task.getTasks(id);
};

const getTask = () => {
	return Task.getTask();
};

const getTaskCategoriesTree = () => {
	return Task.getTaskCategoriesTree();
};

const acknowledgeTask = (id) => {
	return Task.acknowledgeTask(id);
};

const setFilters = (filters) => {
	_.each(filters, (values, key) => {
		Task.filters[key] = values;
	});
	Task.startPolling();
};

const wait = (id, callback) => {
	Task.fetchTask(id, false)
		.then((task) => {
			if (!task || _.includes(['pending', 'running'], task.status.toLowerCase())) {
				setTimeout(wait, 3000, id, callback);
				return;
			}

			callback(task);
		});
};

const handleListSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	_.each(tasksCallback, (callback) => {
		callback(state);
	});
};

const handleDetailsSubscription = (store) => {
	if (!store) {
		return;
	}
	
	taskCallback(store.state);
};

const subscribeList = (callbacks) => {
	tasksCallback = _.concat(tasksCallback, callbacks);

	Task.subscribeToProperties(['tasks', 'isPollingTasks'], handleListSubscription);
};

const subscribeDetails = (callback) => {
	taskCallback = callback;
	Task.subscribeToProperties(['task'], handleDetailsSubscription);
};

const subscribeToOngoingTasksCount = (callback) => {
	Task.subscribeToProperties(['ongoingTasksCount'], (store) => {
		if (!store) {
			return;
		}
		
		let state = store.state;
		if (_.isNull(state.ongoingTasksCount)) {
			return;
		}
		
		callback(state.ongoingTasksCount);
	});
};

export {
	subscribeList,
	subscribeDetails,
	subscribeToOngoingTasksCount,
	isPollingTasks,
	getTasks,
	getTask,
	getTaskCategoriesTree,
	acknowledgeTask,
	setFilters,
	wait
};
