import * as service from 'alert/service';
import * as taskService from 'task/service';

export default function destroy() {
	const destroy = (event) => {
		event.preventDefault();
		let $deleteButton = $(event.currentTarget);
		let $dropdownToggle = $deleteButton.closest('.dropdown').find('.dropdown-toggle');
		let uid = $deleteButton.data('uid');
		let contactPoint;
		service.getContactPoints()
			.then((contactPoints) => {
				contactPoint = _.find(contactPoints, { uid: uid });
				confirm(app.t('alert:phrases.confirmDeleteContactPoint', { name: contactPoint.name }), callback, app.t('labels.delete'));
			});
		
		function callback(result) {
			if (result === false) {
				return;
			}
			
			$dropdownToggle.button('loading');
			service.deleteContactPoint(contactPoint.uid)
				.then((task) => {
					let notification = app.notifier.add({
						text: app.t('alert:phrases.deletingContactPoint', { name: contactPoint.name }),
						type: 'info',
						sticker: false,
						closer: false,
						closerHover: false
					});
					taskService.wait(task.jobId, (task) => {
						notification.hide();
						if (task.status.toLowerCase() === 'failed') {
							app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.couldNotDeleteContactPoint', { name: contactPoint.name }), type: 'error' });
						}
						if (task.status.toLowerCase() === 'succeeded') {
							app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.contactPointDeleted', { name: contactPoint.name }), type: 'success' });
						}
					});
				})
				.catch((error) => {
					app.notifier.add({ text: (error?.response?.data?.message ?? app.t('alert:phrases.couldNotDeleteContactPoint', { name: contactPoint.name })), type: 'error' });
					$dropdownToggle.button('reset');
				});
		}
	};
	
	const init = (parent) => {
		parent.getContainer()
			.on('click', '.delete', destroy);
	};
	
	return {
		init
	};
}
