import bootbox from 'bootbox';

export default function dialog() {
	bootbox.setDefaults({
		size: 'small',
		closeButton: false
	});
	
	window.alert = (message, labelOk, callback) => {
		callback = (callback || (() => {}));
		labelOk = (labelOk || 'Ok');
		return bootbox.alert({
			title: 'Info',
			message: message,
			buttons: {
				ok: {
					label: labelOk,
					className: 'btn-primary focus'
				}
			},
			callback: callback
		});
	};
	
	window.confirm = (message, callback, labelConfirm, labelCancel) => {
		callback = (callback || (() => {}));
		labelConfirm = (labelConfirm || app.t('labels.yes'));
		labelCancel = (labelCancel || app.t('labels.cancel'));
		let labelConfirmClasses = [];
		if (/delete|remove|destroy|revoke|export|disable|warning|power off|shut down|shutdown|restart|reboot|reset/i.test(message)) {
			labelConfirmClasses.push('btn-danger');
		} else {
			labelConfirmClasses.push('btn-primary');
		}
	
		labelConfirmClasses.push('focus');
		return bootbox.confirm({
			title: app.t('labels.confirm'),
			message: message,
			swapButtonOrder: false,
			buttons: {
				confirm: {
					label: labelConfirm,
					className: labelConfirmClasses.join(' ')
				},
				cancel: {
					label: labelCancel,
					className: 'btn-outline-secondary cancel'
				}
			},
			callback: callback
		});
	};
	
	window.dialog = (title, message, buttons, options) => {
		return bootbox.dialog({
			title: title,
			message: message,
			buttons: buttons,
			...options
		});
	};
	
	window.prompt = (message, labelConfirm, labelCancel, callback) => {
		callback = (callback || (() => {}));
		labelConfirm = (labelConfirm || app.t('labels.yes'));
		labelCancel = (labelCancel || app.t('labels.cancel'));
		let labelConfirmClasses = [];
		if (/delete|remove|destroy|revoke|export|disable|warning|power off|shut down|shutdown|restart|reboot|reset/i.test(message)) {
			labelConfirmClasses.push('btn-danger');
		}
	
		return bootbox.prompt({
			title: message,
			inputType: 'password',
			buttons: {
				confirm: {
					label: labelConfirm,
					className: labelConfirmClasses.join(' ')
				},
				cancel: {
					label: labelCancel,
					className: 'btn-outline-secondary cancel'
				}
			},
			callback: callback
		});
	};
}
