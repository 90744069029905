import Node from 'store/node';

let callbackCollection = [];

const fetchTime = () => {
	return Node.fetchTime();
};

const fetchTimezones = () => {
	return Node.fetchTimezones();
};

const getTimezones = () => {
	return Node.getTimezones();
};

const updateTime = (data) => {
	return Node.updateTime(data);
};

const updateTimezone = (data) => {
	return Node.updateTimezone(data);
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	_.each(callbackCollection, (callback) => {
		callback(state);
	});
};

const subscribe = (callbacks) => {
	callbackCollection = _.concat(callbackCollection, callbacks);
	
	Node.subscribeToProperties(['timezones'], handleSubscription);
	
	Node.getTimezones();
};

export {
	subscribe,
	fetchTimezones,
	fetchTime,
	getTimezones,
	updateTime,
	updateTimezone
};
