$.extend(bootstrap.Tooltip.Default, {
	animation: false,
	container: document.body,
	html: true,
	sanitize: false,
	placement: 'right',
	trigger: 'hover',
	selector: '[data-bs-toggle="tooltip"]',
	delay: {
		show: 0,
		hide: 0
	}
});

$.extend(bootstrap.Popover.Default, {
	animation: false,
	container: document.body,
	html: true,
	sanitize: false,
	placement: 'auto',
	trigger: 'hover',
	selector: '[data-bs-toggle="popover"]'
});

$(document.body).tooltip();
$(document.body)
	.on('inserted.bs.tooltip', (event) => {
		let $target = $(event.target);
		let tooltip = bootstrap.Tooltip.getInstance($target);
		let insertedTooltipId = $(tooltip.tip).attr('id');
		$(document.body).find(`.tooltip.show:not([id="${insertedTooltipId}"])`).remove();
	});
