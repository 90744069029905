String.prototype.pastTense = function () {
	return `${this.replace(/([^aeiouy])y$/,'$1i').replace(/([^aeiouy][aeiou])([^aeiouy])$/, '$1$2$2').replace(/e$/, '')}ed`;
};

String.prototype.nl2br = function () {
	return this.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

Array.prototype.remove = function (element) {
	let match = -1;
	while ((match = this.indexOf(element)) > -1) {
		this.splice(match, 1);
	}
};
