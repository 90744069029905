let resources = {};
const files = require.context('./../../i18n', true, /\.json$/i);
files.keys().map((path) => {
	let [ language, file ] = _.drop(_.split(path, '/'));
	let [ namespace ] = _.split(file, '.json');
	if (!resources[language]) {
		resources[language] = {};
	}
	resources[language][_.camelCase(namespace)] = files(path);
});

export default resources;
