import Galactus from '../galactus';
import template from './badge.html';

customElements.define('gt-badge', class Badge extends Galactus {
	#badge;
	#template = template;
	
	static get observedAttributes() {
		return ['color', 'tone'];
	}
	
	constructor() {
		super();
		
		this.#render();
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'color') {
			this.#setColor(newValue, this.getAttribute('tone'));
		}
		if (name === 'tone') {
			this.#setColor(this.getAttribute('color'), newValue);
		}
	}
	
	set color(value) {
		this.setAttribute('color', value);
	}
	
	set tone(value) {
		this.setAttribute('tone', value);
	}
	
	#setColor(color, tone) {
		color = color || 'blue';
		tone = tone || '500';
		let classList = this.#badge.classList;
		let classesToRemove = Array.from(classList).filter((className) => { return className.startsWith('bd-'); });
		if (classesToRemove.length > 0) {
			classList.remove(...classesToRemove);
		}
		classList.add(`bd-${color}-${tone}`);
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#badge = this.shadowRoot.querySelector('span');
		if (!this.hasAttribute('color')) {
			this.#setColor();
		}
	}
});
