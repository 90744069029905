import modal from 'views/alert/modals/configure.html';
import contactPointsPartial from 'views/alert/partials/contact_points.html';
import * as create from 'alert/contact_point/create';
import * as update from 'alert/contact_point/update';
import destroyObj from 'alert/contact_point/destroy';
import * as service from 'alert/service';
import * as taskService from 'task/service';

let that;
let destroy = destroyObj();
let loadingQueue = null;
let verificationCode = null;
let notification = null;
let showedNotification = false;
let $modal;

const getContainer = () => {
	return getModal();
};

const getModal = () => {
	return $modal;
};

const setModal = () => {
	$modal = $('#alerts-configure');
};

const showModal = () => {
	service.getEmailProviders()
		.then((emailProviders) => {
			if (emailProviders === false || !_.isEmpty(emailProviders)) {
				return;
			}
			
			getModal().modal('show');
		});
};

const hideNotification = () => {
	if (!notification) {
		return;
	}

	notification.hide();
};

const showNotification = () => {
	if (showedNotification) {
		return;
	}
	
	let isOnFirmwareUpdatePage = (app.controller === 'firmware' && app.view === 'update');
	let isOnSystemRestartPage = (app.controller === 'system' && app.view === 'restart');
	if (isOnFirmwareUpdatePage || isOnSystemRestartPage || !_.isNull(notification)) {
		return;
	}
	
	notification = app.notifier.add({ text: app.t('alert:phrases.pleaseConfigure'), type: 'warning', callbacks: { afterHidden: () => { notification = null; } } });
	showedNotification = true;
};

const setFormData = (event) => {
	hideNotification();
	loadingQueue = [
		service.getEmailProviders(),
		service.getContactPoints()
	];
	Promise.all(loadingQueue)
		.then(([emailProviders, contactPoints]) => {
			if (emailProviders === false || contactPoints === false) {
				throw new Error();
			}
			
			if (!_.isEmpty(emailProviders)) {
				let emailProvider = _.find(emailProviders, { providerType: 'SMTP' });
				getModal().find('.uid').val(emailProvider.uid);
				getModal().find('.host').val(emailProvider.host);
				getModal().find(`.encryption.${emailProvider.transport.toLowerCase()}`).prop('checked', true);
				getModal().find('.port').val(emailProvider.port);
				getModal().find('.username').val(emailProvider.username);
				getModal().find('.sender-email').val(emailProvider.senderEmail);
				if (_.isEmpty(emailProvider.username)) {
					getModal().find('.authentication-toggle').prop('checked', false).trigger('change');
				}
				if (_.isEmpty(emailProvider.senderEmail)) {
					getModal().find('.sender-address-toggle').prop('checked', false).trigger('change');
				}
			}
			
			morphdom(_.first(getModal().find('.contact-points')), app.render(contactPointsPartial, { contactPoints }));
			
			getModal().find('.fetching').addClass('d-none');
			getModal().find('.modal-body > .row').removeClass('d-none');
			getModal().find('.modal-footer .back, .modal-footer .next').removeClass('d-none');
			_.first(getModal().find('.modal-footer .back')).disabled = isFirstStepSelected();
		})
		.catch((error) => {
			getModal().find('.fetching').addClass('d-none');
			getModal().find('.oopsy').removeClass('d-none');
		})
		.then(() => {
			loadingQueue = null;
		});
};

const hideModal = (event) => {
	let $verificationCode = getModal().find('.verification-code');
	getModal().formValidation('updateMessage', $verificationCode, 'blank', '');
	getModal().formValidation('updateStatus', $verificationCode, 'NOT_VALIDATED', null);
	getModal().find('form').reset();
	getModal().formValidation('resetForm');
};

const restoreModal = (event) => {
	verificationCode = null;
	getModal().find('.modal-body > .row, .verification-code-resend, .modal-footer gt-button:not(.cancel)').addClass('d-none');
	getModal().find('.fetching, .authentication, .sender-address, .verification-code-text').removeClass('d-none');
	_.first(getModal().find('.modal-footer .back')).disabled = true;
	_.first(getModal().find('.modal-footer .next')).disabled = false;
	getModal().find('.modal-footer .next').html(app.t('alert:labels.sendTestEmail'));
	getModal().find('.nav.nav-stacked > .nav-item > .nav-link, .tab-content.tab-stacked .tab-pane').removeClass('active');
	getModal().find('.nav.nav-stacked > .nav-item:first-child > .nav-link, .tab-content.tab-stacked .tab-pane:first-child').addClass('active');
};

const navigateSteps = (event) => {
	let $button = $(event.currentTarget);
	if (_.first($button).disabled) {
		return;
	}
	
	let $currentTab = getModal().find('.nav-tabs.nav-stacked .nav-item .nav-link.active').parent();
	let $currentTabPane = getModal().find('.tab-content .tab-pane.active');
	
	if ($button.hasClass('next')) {
		let $formValidation = getModal().data('formValidation');
		$formValidation.validateContainer($currentTabPane);
		if ($formValidation.isValidContainer($currentTabPane)) {
			if ($currentTabPane.hasClass('smtp-server-configure')) {
				_.first(getModal().find('.modal-footer .back')).disabled = true;
				if (_.isNull(verificationCode)) {
					_.first(getModal().find('.modal-footer .next')).setState('sending-test');
					sendTestEmail()
						.then((task) => {
							if (task === false) {
								app.notifier.add({ text: app.t('alert:phrases.couldNotSendTestEmail'), type: 'error' });
								_.first(getModal().find('.modal-footer .next')).reset();
								return;
							}
							
							taskService.wait(task.jobId, (task) => {
								if (task.status.toLowerCase() === 'failed') {
									app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.couldNotSendTestEmail'), type: 'error' });
									_.first(getModal().find('.modal-footer .next')).reset();
								}
								if (task.status.toLowerCase() === 'succeeded') {
									verificationCode = task.result.verificationCode;
									_.first(getModal().find('.verification-code')).disabled = false;
									_.first(getModal().find('.modal-footer .back')).disabled = true;
									_.first(getModal().find('.modal-footer .next')).reset();
									getModal().find('.modal-footer .next').html(app.t('alert:labels.validateAndSave'));
									getModal().find('.verification-code-text').addClass('d-none');
									getModal().find('.verification-code-resend').removeClass('d-none');
									getModal().find('.verification-code').focus();
								}
							});
						});
				} else {
					_.first(getModal().find('.modal-footer .next')).setState('loading');
					_.first(getModal().find('.try-sending-again')).disabled = true;
					saveSmtpServer()
						.then((task) => {
							taskService.wait(task.jobId, (task) => {
								if (task.status.toLowerCase() === 'failed') {
									app.notifier.add({ text: task.statusMessage || app.t('alert:phrases.couldNotSaveSmtpConfiguration'), type: 'error' });
									_.first(getModal().find('.modal-footer .next')).reset();
								}
								if (task.status.toLowerCase() === 'succeeded') {
									verificationCode = null;
									let $nextTab = $currentTab.next();
									$nextTab.find('.nav-link').tab('show');
									_.first(getModal().find('.modal-footer .back')).disabled = false;
									getModal().find('.next').toggleClass('d-none', isLastStepSelected());
									getModal().find('.finish').toggleClass('d-none', !isLastStepSelected());
								}
							});
						})
						.catch((error) => {
							app.notifier.add({ text: app.t('alert:phrases.couldNotSaveSmtpConfiguration'), type: 'error' });
							_.first(getModal().find('.modal-footer .next')).reset();
							getModal().find('.modal-footer .next').html(app.t('alert:labels.validateAndSave'));
						});
				}

				return;
			}
		}
	}
	
	if ($button.hasClass('back')) {
		let $previousTab = $currentTab.prev();
		$previousTab.find('.nav-link').tab('show');
		navigateToFirstStep();
	}
};

const navigateToFirstStep = () => {
	verificationCode = null;
	let $verificationCode = getModal().find('.verification-code');
	$verificationCode.val('');
	getModal().formValidation('updateMessage', $verificationCode, 'blank', '');
	getModal().formValidation('updateStatus', $verificationCode, 'NOT_VALIDATED', null);
	_.first($verificationCode).disabled = true;
	getModal().find('.verification-code-text').removeClass('d-none');
	_.first(getModal().find('.try-sending-again')).disabled = false;
	_.first(getModal().find('.modal-footer .back')).disabled = isFirstStepSelected();
	_.first(getModal().find('.modal-footer .next')).reset();
	getModal().find('.modal-footer .next').html(app.t('alert:labels.sendTestEmail'));
	getModal().find('.verification-code-resend, .finish').addClass('d-none');
	getModal().find('.next').removeClass('d-none');
};

const isLastStepSelected = () => {
	return getModal().find('.modal-body .nav-tabs.nav-stacked > .nav-item:last-child > .nav-link').hasClass('active');
};

const isFirstStepSelected = () => {
	return getModal().find('.modal-body .nav-tabs.nav-stacked > .nav-item:first-child > .nav-link').hasClass('active');
};

const sendTestEmail = () => {
	verificationCode = null;
	let data = serializeJSON(_.first(getModal().find('.smtp-server-configure form')), { useIntKeysAsArrayIndex: true });
	let testEmailAddress = data.testEmailAddress;
	delete data.testEmailAddress;
	if (!data.useAuthentication) {
		delete data.username;
		delete data.password;
	}
	if (!data.useSenderEmail) {
		delete data.senderEmail;
	}
	delete data.useAuthentication;
	delete data.useSenderEmail;
	let configuration = {
		testEmailAddress: testEmailAddress,
		emailProvider: data
	};
	return service.sendTestEmail(configuration);
};

const saveSmtpServer = () => {
	let data = serializeJSON(_.first(getModal().find('.smtp-server-configure form')), { useIntKeysAsArrayIndex: true });
	delete data.testEmailAddress;
	if (!data.useAuthentication) {
		delete data.username;
		delete data.password;
	}
	if (!data.useSenderEmail) {
		delete data.senderEmail;
	}
	delete data.useAuthentication;
	delete data.useSenderEmail;
	
	return service.saveSmtpServer(data);
};

const updatePort = (event) => {
	let $radio = $(event.currentTarget);
	let port = '';
	if ($radio.val().toLowerCase() === 'tls') {
		port = '587';
	}
	if ($radio.val().toLowerCase() === 'ssl') {
		port = '465';
	}
	getModal().find('.port').val(port);
	getModal().formValidation('revalidateField', 'port');
};

const toggleAuthentication = (event) => {
	let $checkbox = $(event.currentTarget);
	getModal().find('.authentication').toggleClass('d-none', !$checkbox.prop('checked'));
};

const toggleSenderAddress = (event) => {
	let $checkbox = $(event.currentTarget);
	getModal().find('.sender-address').toggleClass('d-none', !$checkbox.prop('checked'));
};

const render = (state) => {
	if (_.isNull(state.emailProviders) || state.emailProviders === false) {
		return;
	}
	
	morphdom(
		_.first(getModal().find('.contact-points')),
		app.render(contactPointsPartial, { contactPoints: state.contactPoints })
	);
	
	if (!_.isEmpty(state.emailProviders)) {
		app.menu.$menu.find('.alerts gt-badge').addClass('d-none');
		return;
	}
	
	app.menu.$menu.find('.alerts gt-badge').removeClass('d-none');
	showNotification();
};

const init = function () {
	that = this;
	$app.append(app.render(modal));
	
	setModal();
	getModal()
		.formValidation({
			fields: {
				host: {
					selector: '.host',
					validators: {
						notEmpty: {}
					}
				},
				port: {
					selector: '.port',
					validators: {
						notEmpty: {},
						port: {}
					}
				},
				credentials: {
					selector: '.username, .password',
					excluded: false,
					validators: {
						callback: {
							callback: (value, validator, $field) => {
								if (!_.first(getModal().find('.authentication-toggle')).checked) {
									return true;
								}
								
								if (!FormValidation.Validator.notEmpty.validate(validator, $field)) {
									return { valid: false, message: app.t('validator:phrases.cannotBeEmpty') };
								}
								
								return true;
							}
						}
					}
				},
				senderEmail: {
					selector: '.sender-email',
					excluded: false,
					validators: {
						callback: {
							callback: (value, validator, $field) => {
								if (!_.first(getModal().find('.sender-address-toggle')).checked) {
									return true;
								}
								
								if (!FormValidation.Validator.notEmpty.validate(validator, $field)) {
									return { valid: false, message: app.t('validator:phrases.cannotBeEmpty') };
								}
								
								if (!FormValidation.Validator.emailAddress.validate(validator, $field, { multiple: false })) {
									return { valid: false, message: app.t('validator:phrases.invalidEmail') };
								}
								
								return true;
							}
						}
					}
				},
				testEmailRecipient: {
					selector: '.test-email-recipient',
					validators: {
						notEmpty: {},
						emailAddress: { message: app.t('validator:phrases.invalidEmail') }
					}
				},
				verificationCode: {
					selector: '.verification-code',
					validators: {
						notEmpty: {},
						callback: {
							message: app.t('validator:phrases.smtpVerificationCodeMismatch'),
							callback: (value) => {
								return value === verificationCode;
							}
						}
					}
				}
			}
		})
		.on('show.bs.modal', setFormData)
		.on('hide.bs.modal', hideModal)
		.on('hidden.bs.modal', restoreModal)
		.on('change', '.encryption', updatePort)
		.on('change', '.authentication-toggle', toggleAuthentication)
		.on('change', '.sender-address-toggle', toggleSenderAddress)
		.on('click', '.try-sending-again', navigateToFirstStep)
		.on('click', '.modal-footer .back, .modal-footer .next', navigateSteps)
		.on('click', '.finish', () => { getModal().modal('hide'); });
	
	create.init();
	update.init();
	destroy.init(that);
	
	service.subscribe([render]);
};

export {
	init,
	getContainer,
	showModal
};
