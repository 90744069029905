(function ($) {
	$(document).ready(function () {
		$.fn.button = function (action) {
			this.each(function() {
				if (action !== 'reset' && $(this).data(`${action}-text`)) {
					let originalText = $(this).data('original-text') || $(this).html();
					$(this).data('original-text', originalText).html($(this).data(`${action}-text`))
						.addClass('disabled')
						.attr('disabled', true);
				}

				if (action === 'reset' && $(this).data('original-text')) {
					$(this).html($(this).data('original-text'))
						.removeClass('disabled')
						.attr('disabled', false);
					$(this).removeData('original-text')
				}
				return $(this);
			});
			return this;
		}
	})
}(jQuery));
