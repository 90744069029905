import Galactus from '../galactus';
import template from './textarea.html';

customElements.define('gt-textarea', class Textarea extends Galactus {
	#textarea;
	#initialValue;
	#initialDisabled;
	#initialReadOnly;
	#slot;
	#template = template;
	
	static get observedAttributes() {
		return ['label', 'disabled', 'readonly', 'error'];
	}
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.#textarea.innerHTML = this.#initialValue;
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
		this.removeAttribute('readonly');
		if (this.#initialReadOnly) {
			this.toggleAttribute('readonly');
		}
		this.removeAttribute('error');
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'label') {
			this.#setLabel(newValue);
		}
		if (name === 'disabled') {
			this.#textarea.disabled = (newValue !== null);
		}
		if (name === 'readonly') {
			this.#textarea.readOnly = (newValue !== null);
		}
		if (name === 'error') {
			this.shadowRoot.querySelector('.invalid-feedback.component').innerHTML = newValue;
			if (newValue === '' || newValue === null) {
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.add('d-none');
				this.shadowRoot.querySelector('.form-group').classList.remove('has-error');
			} else {
				this.shadowRoot.querySelector('.form-group').classList.add('has-error');
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.remove('d-none');
			}
		}
	}
	
	get name() {
		return this.getAttribute('name');
	}
	
	get type() {
		return 'textarea';
	}
	
	get nodeName() {
		return 'TEXTAREA';
	}
	
	set label(value) {
		this.setAttribute('label', value);
	}
	
	get value() {
		return this.#textarea.value;
	}
	
	set value(value) {
		this.#textarea.innerHTML = value;
	}
	
	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	get readonly() {
		return this.hasAttribute('readonly');
	}
	
	set readonly(value) {
		this.removeAttribute('readonly');
		if (String(value) === 'true') {
			this.toggleAttribute('readonly');
		}
	}
	
	get error() {
		return this.getAttribute('error');
	}
	
	set error(value) {
		if (typeof value === 'string' || typeof value === 'number') {
			this.setAttribute('error', value);
		} else {
			this.removeAttribute('error');
		}
	}
	
	#setLabel(value) {
		let label = value;
		if (this.hasAttribute('help-text')) {
			label += `<span class="help-inline ms-1" data-bs-toggle="tooltip" title="${this.getAttribute('help-text')}"><i class="fas fa-question-circle text-body-secondary"></i></span>`;
		}
		this.shadowRoot.querySelector('label').innerHTML = label;
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#textarea = this.shadowRoot.querySelector('textarea');
		this.#textarea.placeholder = this.getAttribute('placeholder') || ' ';
		this.#textarea.rows = this.getAttribute('rows') || '5';
		this.#initialValue = this.getAttribute('value') || '';
		this.#initialDisabled = this.hasAttribute('disabled');
		this.#initialReadOnly = this.hasAttribute('readonly');
		
		this.#slot = this.shadowRoot.querySelector('slot');
	}
	
	#attachEventListeners() {
		this.#slot.addEventListener('slotchange', (event) => {
			this.#textarea.value = this.innerHTML;
		});
		
		this.shadowRoot.querySelectorAll('label').forEach((trigger) => {
			new bootstrap.Tooltip(trigger);
		});
	}
});
