import Node from 'store/node';
import Auth from 'store/auth';

let callbackCollection = [];

const getProduct = () => {
	return Node.getProduct();
};

const getSettings = () => {
	return Auth.getSettings();
};

const restart = () => {
	return Node.restart();
};

const shutDown = () => {
	return Node.shutDown();
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	_.each(callbackCollection, (callback) => {
		callback(state);
	});
};

const subscribe = (callbacks) => {
	callbackCollection = _.concat(callbackCollection, callbacks);
	
	Node.subscribeToProperties(['isRestarting', 'isShuttingDown', 'isShutDown'], handleSubscription);
};

export {
	getProduct,
	getSettings,
	restart,
	shutDown,
	subscribe
};
